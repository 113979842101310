import React, { useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import "./thankPage.css";
import { useLocation } from "react-router-dom";
import FieldButtons from "../common/FieldButtons";
import axios from "../../utils/lib/axios";
import {Helmet} from "react-helmet";

function ThankYou() {
  const { state } = useLocation();
  const [userdata, setUserdata] = useState([]);
  const home = () => {
    window.location.href = "/products";
  };

  const getTotal = () => {
    let total = 0;
    state.map((item) => {
      total = total + item.price * item.fQuantity;
      return total;
    });

    return total;
  };
  // const [fData, setFData] = useState([]);

  const loadData = async () => {
    const { data } = await axios.get("/delivery");
    setUserdata(data);
    // setFData(data);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="thank">
      <Helmet>
        <title>Seafood Shop Eka - Thank You Page</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      <div className="topText">
        <CheckCircleOutlinedIcon className="chekIcon" sx={{ fontSize: 35 }} />
        <h2>Thank You</h2>
        <p>Your Order Placed!</p>
        <p>
          If you want to track your order status place go your dashboard "My
          Order List" tab.
        </p>
        <p>Any Queries Contact us (0777631108/ 070 339 0432)</p>
      </div>

      <div className="mainDiv">
        <div className="mainDiv2">
          <div className="orderConfrm">
            <div className="confirmation">
              <h2 className="confirmationh2">Order Placed Number</h2>
              <div className="alignc">
                <p className="confirmationp">
                  {Math.floor(Math.random() * 10000 + 1)}
                </p>
              </div>
            </div>
            {/* {fData.map((item) => ( */}
            <div className="cusInfo">
              <h3>Customer information</h3>

              <div className="contacInfo">
                <div>
                  <h4>Contact information</h4>
                  <p>
                    {userdata.phoneNumber}
                    <br />
                    {userdata.email}
                  </p>
                </div>
                <div className="alignc">
                  <p>Payment method : Cash on delivery</p>
                  <h4>LKR {getTotal()}.00</h4>
                </div>
              </div>

              <div className="addresses">
                <div>
                  <h4>Shipping address</h4>
                  <p>
                    {userdata.address}
                    <br />
                    {userdata.phoneNumber}
                  </p>
                </div>
                <div className="alignc">
                  <h4>Billing address</h4>
                  <p>
                    {userdata.address}
                    <br />
                    {userdata.phoneNumber}
                  </p>
                </div>
              </div>

              <div>
                <h4>Shipping method</h4>
                <p>Vehicle delivery</p>
              </div>
            </div>
            {/* ))} */}
          </div>

          <div className="odrSummary">
            <div className="Summary">
              <h2 className="Summaryor">Order summary</h2>
              {state.map((item) => (
                <div className="picTextPrice">
                  <div className="picTextPricep">
                    <div className="picTextPricepimg">
                      <img src={item.image1} alt="pic" />
                    </div>
                    <div className="middle">
                      <h2 className="Summaryh2">
                        {item.subName} <br />({item.weight}g)portions
                      </h2>
                      <p className="OStextP">Quantity: {item.fQuantity}</p>
                      <p className="Summaryp">
                        Category: <span>{item.category[0].name}</span>{" "}
                      </p>
                      <p className="Summaryp">Store: Seafood Shop Eka</p>
                    </div>
                  </div>
                  <p className="lkrPrice">LKR {item.price}.00</p>
                </div>
              ))}

              <hr />

              <div className="subTotal">
                <h2 className="subdetails">Subtotal</h2>
                <p className="subdetails">LKR {getTotal()}.00</p>
              </div>

              <div className="Shipping">
                <h2 className="subdetails">Shipping</h2>
                {getTotal() < 50000 ? (
                  <p className="subdetails">+ Delivery Charge</p>
                ) : (
                  <p className="subdetails">Free</p>
                )}
              </div>
              <hr />

              <div className="grandTotal">
                <h4>Grand total</h4>
                <h3>LKR {getTotal()}.00 </h3>
              </div>
              <div style={{ textAlign: "end", paddingRight: "1rem" }}>
                {getTotal() < 50000 ? (
                  <p className="subdetails">+ Delivery Charge</p>
                ) : (
                  <p className="subdetails">Free</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ContinueShoppingBtn">
        <FieldButtons
          label="Continue Shopping"
          fontcolor="#FFFFFF"
          buttoncolor="#41630F"
          buttonborder="none"
          hoverbgcolor="#41630F"
          onClick={home}
        />
      </div>
    </div>
  );
}

export default ThankYou;
