import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SellerCard from "../../components/Home/BestSeller/CardView/SellerCard";
import axios from "../../utils/lib/axios";
import "./myWishlist.css";
import Order from "./order/Order";
import ChangePassword from "./changePassword/ChangePassword";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyWishList from "./EmptyWishList";
import { Helmet } from "react-helmet";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";


import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#41630F",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Products() {

    const mobile = useMediaQuery("(max-width: 820px)");

  const [screen, setScreen] = useState("order");
  const changeScreen = (text) => {
    setScreen(text);
  };

  const auth = useSelector((state) => state.auth.accessToken);
  let navigate = useNavigate();
  if (!auth) {
    navigate("/login");
  }

  const Product = () => {
    navigate("/products");
  };
  const [categoryCard, setCategoryCard] = useState([]);

  const loadData = async () => {
    const { data } = await axios.get("/subCategory/myFavorite");
    setCategoryCard(data);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);


    const [anchorEl, setAnchorEl] = useState(null);
    const [dropdown, setDropdown] = useState(false);
    const [heading, setHeading] = useState("My Order List");

    const handleClick = (event) => {
      setDropdown(!dropdown);
      setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
      setAnchorEl(null);
    };

    // const [screen, setScreen] = useState("order");
    // const changeScreen = (text) => {
    //   setScreen(text);
    // };

    // const changeOrder = () => {
    //   setHeading("Order History");
    // };

    // const changeWishList = () => {
    //   setHeading("My Wishlist");
    // };
    // const changePassword = () => {
    //   setHeading("Change Password");
    // };

  return (
    <Grid className="wishList-grid" container spacing={3}>
      <Helmet>
        <title>Seafood Shop Eka - User Dashboard</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      {!mobile && (
        <Grid container item xs={12} sm={4} md={3} lg={3}>
          <div className="wishList-div">
            <div className="btngroup">
              <p className="wishList-heading1">My Order List</p>
              <button
                sx={{}}
                className="wishListBtn"
                onClick={() => {
                  changeScreen("order");
                }}
              >
                <p className="btnP">My Order List</p>
              </button>

              <div className="buttonDiv">
                <p className="wishList-heading1">My Wishlist</p>
                <button
                  className="wishListBtn"
                  onClick={() => {
                    changeScreen("myWishlist");
                  }}
                >
                  <p className="btnP">My Wishlist</p>
                </button>
              </div>
              <div className="buttonDiv">
                <p className="wishList-heading1">Manage My Account</p>
                <button
                  className="wishListBtn"
                  onClick={() => {
                    changeScreen("changePassword");
                  }}
                >
                  <p className="btnP">Change Password</p>
                </button>
              </div>
            </div>
          </div>
        </Grid>
      )}
      {mobile && (
        <div style={{ width: "100%", marginTop: "2rem", marginLeft: "1rem" }}>
          <div>
            <div
              onClick={handleClick}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#FFFFFF",
                color: "#41630F",
                alignSelf: "center",
                padding: "0 0.4rem",
                boxShadow: "2px 2px 2px 2px #aaaaaa",
                borderRadius: "5px",
              }}
            >
              <div>
                <p>{heading}</p>
              </div>
              <div>
                {/* <Button
            variant="contained"
            style={{
              backgroundColor: "#FFFFFF",
              color: "#41630F",
            }}
            onClick={handleClick}
          >
            Open Menu
          </Button> */}
                {dropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </div>
            </div>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <ListItemText
                  primary="My Order List"
                  onClick={() => {
                    changeScreen("order");
                    setHeading("My Order List");
                    handleClose();
                  }}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <ListItemText
                  primary="My Wishlist"
                  onClick={() => {
                    changeScreen("myWishlist");
                    setHeading("My Wishlist");
                    handleClose();
                  }}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <ListItemText
                  primary="Change Password"
                  onClick={() => {
                    changeScreen("changePassword");
                    setHeading("Change Password");
                    handleClose();
                  }}
                />
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </div>
      )}

      {screen === "myWishlist" && (
        <Grid
          container
          direction="row"
          flexDirection={"column"}
          justifyContent="space-between"
          item
          xs={12}
          sm={8}
          md={9}
          lg={9}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="cardHeading">My Wishlist</p>
            <div>
              <Button
                style={{ background: "none", margin: 0, padding: 0 }}
                onClick={Product}
              >
                <ArrowBackIosIcon sx={{ fontSize: "1rem", color: "#41630F" }} />
                <p
                  style={{
                    textAlign: "center",
                    color: "#41630F",
                  }}
                >
                  Back to shop
                </p>
              </Button>
            </div>
          </div>
          <Grid>
            {categoryCard.length === 0 ? (
              <Grid md={12}>
                <EmptyWishList />
              </Grid>
            ) : (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                item
              >
                {categoryCard.map((item) => (
                  <Grid key={item.id} item xs={12} sm={6} md={4} lg={4}>
                    <SellerCard item={item} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {screen === "order" && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          item
          xs={12}
          sm={8}
          md={9}
          lg={9}
        >
          <p className="cardHeading">Order History</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ background: "none", margin: 0, padding: 0 }}
              onClick={Product}
            >
              <ArrowBackIosIcon sx={{ fontSize: "1rem", color: "#41630F" }} />
              <p
                style={{
                  textAlign: "center",
                  color: "#41630F",
                }}
              >
                Back to shop
              </p>
            </Button>
          </div>
          <Order />
        </Grid>
      )}

      {screen === "changePassword" && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          item
          xs={12}
          sm={8}
          md={9}
          lg={9}
        >
          <p className="cardHeading">Change Password</p>
          <ChangePassword />
        </Grid>
      )}
    </Grid>
  );
}
