import React, { useState, useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../../store/actions/authActions";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import axios from "../../utils/lib/axios";
import styles from "../../utils/styles/Login.module.css";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

// import { InputAdornment, IconButton } from "@material-ui/core";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";

// validation
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// import FormikField from "../formikField/FormikField";
import TextField from "@mui/material/TextField";
import FieldButtons from "components/common/FieldButtons";
// import OutlinedButtons from 'components/common/OutlinedButtons';
import InputFeild from "components/common/TextFeilds";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    // height: "100%",
  },
  text: {
    backgroundColor:"#FFFFFF",
    width: "100%",
    border: "none",
    color: "#41630F",
    "&:onclick": {
      border: "1px solid #41630F",
    },
  },
  center: {
    textAlign: "center",
    color: "#393E40",
    fontSize: "1.56rem",
    fontWeight: "bold",
    marginTop: 0,
    fontFamily: '"Lato",sans-serif',
    margin: "0",
  },
  padding: {
    padding: theme.spacing(3),
    backgroundColor: "#F5F5F5",
    border: "none",
    width: "45vw",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
    },
  },

  button: {
    backgroundColor: "#2cca5c",
    color: "white",

    "&:hover": {
      backgroundColor: "#2cca5c",
      color: "white",
    },
  },
  button2: {
    marginTop: "1rem",
    color: "#2cca5c",
  },
  lostpassword: {
    color: "#393E40",
    textTransform: "none",
    display: "flex",
    alignItems: "flex-start",

    "&:hover": {
      color: "#41630F",
      background: "none",
    },
  },
  accountbtn: {
    color: "#393E40",
    textTransform: "none",
    "&:hover": {
      background: "none",
    },
  },
  newaccount: {
    color: "#41630F",
    fontWeight:"bold",
  },
  fieldStar: {
    color: "red",
  },
  checkGroup: {
    paddingTop: "1rem",
  },
  paddingLogin: {
    padding: "3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0 2.5rem",
    },
  },
}));


function Login(props) {
const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // const someChangeHandler = (prop) => (event) => {
  //   setShowPassword({ ...showPassword, [prop]: event.target.value });
  // };

  let navigate = useNavigate();

  const ref = useRef(null);

  let initialValues = {
    email: "",
    password: "",
  };

  let SignUpSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
    password: Yup.string()
      .matches(new RegExp(/^.{8,}$/), "At least 8 characters required!")
      .required("Password is required!"),
  });

  const classes = useStyles();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      ref.current?.scrollIntoView({ behavior: "smooth" });
      const data = await axios.post("/user/login", {
        email: e.email,
        password: e.password,
      });
      const { accessToken, message } = data.data;
      props.login(accessToken, message);

      navigate("/dashboard");
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Email or Password is not correct!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "error",
              message: "Email or Password is not correct!",
            }),
          5000
        );
      } else if (error.response.status === 501) {
        setAlert({
          showAlert: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
      }
    }
  };

  return (
    <Grid className={styles.Right} spacing={2} p={1} mt={10}>
      <Helmet>
        <title>Seafood Shop Eka - Login Page</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      <div className={styles.Login}>
        <Grid item className={classes.paddingLogin} md={12}>
          <Card className={classes.padding} variant="outlined">
            <h1 className={classes.center}>Login</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={submit}
              validationSchema={SignUpSchema}
            >
              {({ dirty, isValid }) => {
                return (
                  <Form>
                    <CardContent style={{ padding: 0 }}>
                      <p className={classes.input}>
                        Email <span className={classes.fieldStar}>*</span>
                      </p>
                      <InputFeild
                        name="email"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        Placeholder="Email"
                        margin="dense"
                      />
                      <p className={classes.input}>
                        password <span className={classes.fieldStar}>*</span>
                      </p>
                      <Field
                        fullWidth
                        name="password"
                        placeholder="Password"
                        required
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        as={TextField}
                        margin="dense"
                        helperText={
                          <ErrorMessage
                            className={classes.errormg}
                            name="password"
                          ></ErrorMessage>
                        }
                        className={classes.text}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CardContent>
                    <CardActions style={{ padding: 0 }}>
                      <Grid container justifyContent="flex-start">
                        <Grid item>
                          {/* <Button*/}
                          {/*  variant='contained'*/}
                          {/*  className={classes.button}*/}
                          {/*  disabled={!dirty || !isValid}*/}
                          {/*  type='submit'*/}
                          {/*  size='large'*/}
                          {/*>*/}
                          {/*  login*/}
                          {/*</Button>*/}
                          <FieldButtons
                            label="Login"
                            fontcolor="#FFFFFF"
                            buttoncolor="#41630F"
                            buttonborder="none"
                            hoverbgcolor="#41630F"
                            disabled={!dirty || !isValid}
                          />
                        </Grid>
                      </Grid>
                    </CardActions>
                    <div>
                      {alert.showAlert && (
                        <Grid item md={12} style={{ marginTop: "0.7rem" }}>
                          <Alert
                            severity={alert.severity}
                            onClose={() =>
                              setAlert({
                                ...alert,
                                showAlert: false,
                              })
                            }
                          >
                            {alert.message}
                          </Alert>
                        </Grid>
                      )}
                    </div>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        className={classes.button2}
                        justifyContent="flex-start"
                      >
                        <Button
                          className={classes.lostpassword}
                          onClick={() => navigate("/LostPassword")}
                        >
                          Lost your password?
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Card>
          <Grid
            container
            item
            xs={12}
            className={classes.button2}
            justifyContent="flex-start"
          >
            <Button
              className={classes.accountbtn}
              onClick={() => navigate("/register")}
            >
              Don't you have an account?
              <span className={classes.newaccount}>
                &nbsp; Create your account
              </span>
            </Button>
          </Grid>
        </Grid>
      </div>
      {/* <FieldButtons
        label="gfggjghk iuli"
        fontcolor='#FFFFFF'
        buttoncolor='#41630F'
        buttonborder='none'
        hoverbgcolor='#2E6409'
      />
      <OutlinedButtons
        label="gfggjghk iuli"
        outline='1px solid #41630F'
        fontcolor='#41630F'
        hoverBgcolor='#41630F'
        hoverfontcolor='#FFFFFF'
      /> */}
    </Grid>
  );
}

export default connect(null, { login })(Login);
