import React from 'react';
import Grid from '@mui/material/Grid';
import '../Partnership/partnerships.css'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function Partnerships(Props) {
      const {partnershipData} = Props;

      return (
            <Grid container>
                 {partnershipData.length === 0 ? <Box sx={{ display: 'flex',width:"100%", justifyContent:"center",alignItems:"center",height:"30vh"}}>
          <CircularProgress color="success" />
        </Box> : <Grid className='partners'
                        container
                        spacing={1}
                  >
                        {partnershipData.map((item) => (
                              <Grid key={item.id} className='prtLogo' xs={2} md={2}>
                                    <div> <img src={item.image} alt="prtlogo" /></div>
                              </Grid>
                        ))}

                  </Grid>}
            </Grid>
      );
}
