import React, { useState, useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import styles from "../../utils/styles/Login.module.css";
import { useNavigate } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import axios from "../../utils/lib/axios";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

// import CountryPicker from "./CountryPicker";

// validation
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// import FormikField from "../formikField/FormikField";
import TextField from "@mui/material/TextField";
import FieldButtons from "components/common/FieldButtons";
// import OutlinedButtons from 'components/common/OutlinedButtons';
import InputFeild from "components/common/TextFeilds";
import {Helmet} from "react-helmet";

export default function SignUp() {
  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(5),
      // height: "100%",
    },
    text: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      border: "none",
      color: "#41630F",
      "&:onclick": {
        border: "1px solid #41630F",
      },
    },
    center: {
      textAlign: "center",
      color: "#393E40",
      fontSize: "1.56rem",
      paddingBottom: "1.2rem",
      fontWeight: "bold",
      marginTop: 0,
      fontFamily: '"Lato",sans-serif',
      margin: "0",
    },
    padding: {
      padding: theme.spacing(3),
      backgroundColor: "#F5F5F5",
      border: "none",
      width: "45vw",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
      },
      [theme.breakpoints.down("xs")]: {
        width: "80vw",
      },
    },

    button: {
      backgroundColor: "#2cca5c",
      color: "white",

      "&:hover": {
        backgroundColor: "#2cca5c",
        color: "white",
      },
    },

    button2: {
      marginTop: "1rem",
      color: "#2cca5c",
    },
    lostpassword: {
      color: "#393E40",
      textTransform: "none",
      display: "flex",
      alignItems: "flex-start",

      "&:hover": {
        color: "#41630F",
        background: "none",
      },
    },
    accountbtn: {
      color: "#393E40",
      textTransform: "none",
      "&:hover": {
        background: "none",
      },
    },
    newaccount: {
      color: "#41630F",
      fontWeight:"bold",
    },

    register: {
      fontSize: "0.85rem",
    },
    input: {
      fontSize: "0.85rem",
      fontWeight: "500",
    },
    inputs: {
      padding: "0.5rem",
    },
    starRegister: {
      color: "red",
    },
    hvrSpan: {
      fontWeight: "bold",
      cursor: "pointer",
    },
    paddingBg: {
      padding: "3rem",
      [theme.breakpoints.down("xs")]: {
        padding: "0.8rem 0 1.5rem",
      },
    },
  }));
  const classes = useStyles();

  const ref = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [userType, setuserType] = React.useState("singleUser");
  // eslint-disable-next-line
  const [error, setError] = React.useState(false);

  const handleRadioChange = (event) => {
    setuserType(event.target.value);
    setError(false);
  };

  let initialValues = {
    userName: "",
    userEmail: "",
    country: "",
    phoneNumber: "",
    password: "",
    address: "",
  };

  const phoneRegExp = /^(?:7|0|(?:\+94))[0-9]{9,11}$/;

  let SignUpSchema = Yup.object().shape({
    userName: Yup.string().required("User name is required!"),
    userEmail: Yup.string().email().required("Email is required!"),
    country: Yup.string(),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
      .required("Phone number is required!"),
    password: Yup.string()
      .matches(new RegExp(/^.{8,}$/), "At least 8 characters required!")
      .required("Password is required!"),
    address: Yup.string().required("Address is required!"),
  });
  // eslint-disable-next-line
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const CssRadio = withStyles({
    colorSecondary: {
      color: "#41630f9c",
      "&$checked": {
        color: "#41630F",
      },
    },
    checked: {},
  })(Radio);

  let navigate = useNavigate();

  const PrivacyPolicy = () => {
    navigate("/PrivacyPolicy");
  };

  const submit = async (e) => {

    try {
      ref.current?.scrollIntoView({ behavior: "smooth" });
      await axios.post("/user/signup", {
        userName: e.userName,
        email: e.userEmail,
        country: e.country,
        phoneNumber: e.phoneNumber,
        address: e.address,
        password: e.password,
        role: selectedValue,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Registered successfully",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Registered successfully",
        });
        navigate("/login");
      }, 3000);
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "error",
              message: "Unauthorized!",
            }),
          5000
        );
      } else if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Email already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Email already exists!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server error!",
            }),
          5000
        );
      }
    }
    // try {
    //   const data = await axios.post("/user/login", {
    //     email: e.email,
    //     password: e.password,
    //   });
    //   const { accessToken, message } = data.data;

    //   navigate("/dashboard");

    // } catch (error) {
    //   if (error.response.status === 401) {
    //     setAlert({
    //       showAlert: true,
    //       severity: 'error',
    //       message: 'Unauthorized!',
    //     });
    //   } else if (error.response.status === 501) {
    //     setAlert({
    //       showAlert: true,
    //       severity: 'error',
    //       message:
    //         'You are temporary block. Please contact your administrator!',
    //     });
    //   } else {
    //     setAlert({
    //       showAlert: true,
    //       severity: 'error',
    //       message: 'Server error!',
    //     });
    //   }
    // }
  };
  const [selectedValue, setSelectedValue] = React.useState("customer");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Grid className={styles.Right2} spacing={1} p={1} mt={10}>
      <Helmet>
        <title>Seafood Shop Eka - Register</title>
        <meta name="description" content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!" />
      </Helmet>
      <Grid item md={12} className={classes.paddingBg}>
        <Card className={classes.padding} variant="outlined">
          <h1 className={classes.center}>Register</h1>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={SignUpSchema}
          >
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <CardContent className={classes.inputs}>
                    <p className={classes.input}>Customer Type</p>
                    <RadioGroup
                      aria-label="userType"
                      name="userType"
                      value={userType}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        checked={selectedValue === "customer"}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Customer" }}
                        value="customer"
                        control={<CssRadio />}
                        label="Single"
                      />
                      <FormControlLabel
                        checked={selectedValue === "admin"}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Admin" }}
                        value="admin"
                        control={<CssRadio />}
                        label="Company"
                      />
                    </RadioGroup>
                    <p className={classes.input}>
                      {selectedValue === "admin" ? "Company Name" : "User Name"}
                      <span className={classes.starRegister}>*</span>
                    </p>
                    <InputFeild
                      name="userName"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      margin="dense"
                      Placeholder={
                        selectedValue === "admin" ? "Company Name" : "User Name"
                      }
                    />
                    <p className={classes.input}>
                      {selectedValue === "admin" ? "Company Email" : "Email"}
                      <span className={classes.starRegister}>*</span>
                    </p>
                    <InputFeild
                      name="userEmail"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                      Placeholder={
                        selectedValue === "admin" ? "Company Email" : "Email"
                      }
                    />
                    <p className={classes.input}>Country (optional)</p>
                    {/* <CountryPicker/> */}
                    <InputFeild
                      name="country"
                      component={TextField}
                      variant
                      fullWidth
                      margin="dense"
                      bgColor="#FFFFFF"
                      Placeholder="Country"
                    />
                    <p className={classes.input} ref={ref}>
                      Phone Number{" "}
                      <span className={classes.starRegister}>*</span>
                    </p>
                    <InputFeild
                      name="phoneNumber"
                      component={TextField}
                      variant
                      fullWidth
                      margin="dense"
                      bgColor="#FFFFFF"
                      Placeholder="Phone Number"
                    />
                    <p className={classes.input}>
                      Password <span className={classes.starRegister}>*</span>
                    </p>
                    <Field
                      fullWidth
                      name="password"
                      placeholder="Password"
                      required
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      as={TextField}
                      margin="dense"
                      helperText={
                        <ErrorMessage
                          className={classes.errormg}
                          name="password"
                        ></ErrorMessage>
                      }
                      className={classes.text}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOutlinedIcon
                                  sx={{ color: "#79747E" }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  sx={{ color: "#79747E" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <InputFeild
                      name="password"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                      type="password"
                    /> */}
                    <p className={classes.input}>
                      Address <span className={classes.starRegister}>*</span>
                    </p>
                    <InputFeild
                      name="address"
                      component={TextField}
                      variant
                      fullWidth
                      margin="dense"
                      bgColor="#FFFFFF"
                      required
                      Placeholder="Address"
                    />
                    <p className={classes.register}>
                      Your personal data will be used to support your experience
                      throughout this website, to manage access to your account,
                      and for other purposes described in our{" "}
                      <span className={classes.hvrSpan} onClick={PrivacyPolicy}>
                        Privacy policy
                      </span>
                    </p>
                  </CardContent>
                  <CardActions>
                    <Grid container justifyContent="flex-start">
                      <Grid item>
                        {/* <Button
                          variant='contained'
                          className={classes.button}
                          disabled={!dirty || !isValid}
                          type='submit'
                          size='large'
                        >
                          Register
                        </Button> */}
                        <FieldButtons
                          label="Register"
                          fontcolor="#FFFFFF"
                          buttoncolor="#41630F"
                          buttonborder="none"
                          hoverbgcolor="#41630F"
                          disabled={!dirty || !isValid}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                </Form>
              );
            }}
          </Formik>
          <div>
            {alert.showAlert && (
              <Grid item md={12} margin={1} style={{marginTop:"0.7rem"}}>
                <Alert
                  severity={alert.severity}
                  // onClose={() =>
                  //   setAlert({
                  //     ...alert,
                  //     showAlert: false,
                  //   })
                  // }
                >
                  {alert.message}
                </Alert>
              </Grid>
            )}
          </div>
        </Card>
        <Grid
          container
          item
          xs={12}
          md={6}
          className={classes.button2}
          justifyContent="flex-start"
        >
          <Button
            className={classes.accountbtn}
            onClick={() => navigate("/login")}
          >
            Already have an account?{" "}
            <span className={classes.newaccount}>&nbsp; Login Now</span>
          </Button>
        </Grid>
      </Grid>
      {/* <FieldButtons
        label="gfggjghk iuli"
        fontcolor='#FFFFFF'
        buttoncolor='#41630F'
        buttonborder='none'
        hoverbgcolor='#2E6409'
      />
      <OutlinedButtons
        label="gfggjghk iuli"
        outline='1px solid #41630F'
        fontcolor='#41630F'
        hoverBgcolor='#41630F'
        hoverfontcolor='#FFFFFF'
      /> */}
    </Grid>
  );
}
