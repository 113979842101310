import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FieldButtons from "../../../common/FieldButtons";
import { useNavigate } from "react-router-dom";
import "./dialogPop.css";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { addItem } from "../../../../store/actions/cartActions";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import useMediaQuery from "@mui/material/useMediaQuery";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedDialogs({ item }) {
  const mobile = useMediaQuery("(max-width: 820px)");

    const [cartopen, setCartpen] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const fullProduct = () => {
    navigate("/inStock", { state: item });
  }
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [quantity, setQuantity] = useState(1);

    const alertClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setCartpen(false);
  };
  
   const gotoCart = () => {
     navigate("/ShoppingCart", { state: item });
   };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClick = () => {
    setOpen2(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen(false);
  };
  const directToCheckout = () => {
    navigate("/checkout");
  }

  return (
    <div>
      <div className="itemicondiv">
        <VisibilityIcon
          onClick={handleClickOpen}
          className="icn"
          fontSize="small"
        />
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={"lg"}
        maxWidth={"sm"}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            zIndex: 10,
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="PopDialog">
          <div className="leftColmn">
            <div className="mimg">
              <img src={item.image1} alt="productImage" className="mainImg" />
            </div>
            <div className="hr">
              <hr />
            </div>
            <div className="rowImages">
              <div className="mimg2">
                <img src={item.image2} alt="productImg1" className="rImgs" />
              </div>
              <div className="mimg2">
                <img src={item.image3} alt="productImg1" className="rImgs" />
              </div>
              <div className="mimg2">
                <img src={item.image4} alt="productImg1" className="rImgs" />
              </div>
              <div className="mimg2">
                <img src={item.image5} alt="productImg1" className="rImgs" />
              </div>
            </div>
          </div>
          <div className="rightColmn">
            <div className="desStock">
              <h5>
                {item.subName} ({item.weight}g) portions
              </h5>
              <span>In-Stock</span>
            </div>
            <p className="popTextP">
              Category:{" "}
              <span className="categoryGrnTxt">{item.category[0].name}</span>
            </p>
            <p style={{ lineHeight: "1.3rem" }} className="popTextP">
              <i>Store: Seafood Shop-Eka</i>
            </p>
            <h4>LKR {item.price}.00</h4>
            <hr />
            <div className="QtyLine">
              <p className="qtyTextP">Qty:</p>
              <div className="Qtyform">
                <TextField
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 0 }}
                />
              </div>
            </div>
            <div className="favouriteLine">
              <FavoriteBorderIcon className="favIcnpop" />
              <p className="favTextP">Add to favourites</p>
            </div>
            <div className="fieldBtns">
              <div className="popbtns">
                <FieldButtons
                  label="Buy Now"
                  fontcolor="#FFFFFF"
                  buttoncolor="#393E40"
                  buttonborder="none"
                  hoverbgcolor="#393E40"
                  className="btnpop"
                  onClick={() => {
                    item.fQuantity = Number(quantity);
                    dispatch(addItem(item));
                    directToCheckout();
                  }}
                />
              </div>
              <div className="popbtns">
                <FieldButtons
                  label="Add to Bag"
                  fontcolor="#FFFFFF"
                  buttoncolor="#41630F"
                  buttonborder="none"
                  hoverbgcolor="#41630F"
                  onClick={() => {
                    item.fQuantity = Number(quantity);
                    dispatch(addItem(item));
                    handleClick();
                  }}
                />
              </div>
            </div>
            <hr />
            <p className="hoverTextPdialogp" onClick={fullProduct}>
              View full product details <ChevronRightIcon />
            </p>
            <hr />
          </div>
        </div>
        {mobile && (
          <Snackbar
            open={cartopen}
            onClose={alertClose}
            style={{ fontSize: "1rem", alignItems: "center" }}
          >
            <Alert
              onClose={alertClose}
              severity="success"
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={gotoCart}
              >
                <ShoppingCartIcon
                  style={{ fontSize: "1.1rem", padding: 0, margin: 0 }}
                />{" "}
                <p style={{ padding: "0 0 0 0.5rem", margin: 0 }}>
                  Go to cart!
                </p>
              </div>
            </Alert>
          </Snackbar>
        )}
        <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
          <Alert
            onClose={handleClose2}
            severity="success"
            sx={{ width: "100%" }}
          >
            Your Item Added to Bag!
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  );
}
