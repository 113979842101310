import ShoppingCart from "components/ShoppingCart/ShoppingCart";
import {Helmet} from "react-helmet";

function ShoppingCarts() {
  return (
    <div>
        <Helmet>
            <title>Seafood Shop Eka - Shopping Carts</title>
            <meta name="description" content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!" />
        </Helmet>
      <ShoppingCart />
    </div>
  );
}

export default ShoppingCarts;
