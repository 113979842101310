import React, {useState,useEffect} from 'react';
import './chatButton.css';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export default function ChatButton() {
    const [showScrollTopButton, setShowScrollTopButton] =useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300){
                setShowScrollTopButton(true);
            }
            else{
                setShowScrollTopButton(false);
            }
        })
        // eslint-disable-next-line
    }, [window.scrollY]);
    
    const scrollTop = () =>{
        window.scrollTo({
            top:0,
            behavior:"smooth",
        });
    }
  return (
    <div>
        <div>
                {showScrollTopButton && <button onClick={scrollTop} className='scrollbtn' ><KeyboardDoubleArrowUpIcon className='chatIcon' /></button>}
            </div>
    </div>
  )
}
