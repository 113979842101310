import { ADDITEM, REMOVEITEM, RESETITEM } from '../actions/types';

const initialState = {
    cart: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case ADDITEM:
            const objWithIdIndex = state.cart.findIndex((obj) => obj._id === action.payload.cart._id);
            if(objWithIdIndex >= 0){
                state.cart[objWithIdIndex].fQuantity = state.cart[objWithIdIndex].fQuantity + action.payload.cart.fQuantity;
                return state;
            }else{
                state.cart = [...state.cart, action.payload.cart]
                return state;
            }

        case REMOVEITEM:
            state.cart =  action.payload.cart;
            return state;
        case RESETITEM:
            state.cart =  [];
            return state;

        default:
            return state;
    }
}
