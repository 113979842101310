import React, { useState, useEffect } from 'react';
import axios from '../utils/lib/axios';

import Testimonial from 'components/Home/Testimonial/Testimonial';
import Subscription from 'components/Home/Subscription/Subscription';
import Partnerships from 'components/Home/Partnership/Partnerships';
import Welcome from 'components/Home/Welcome/Welcome';
import BestSeller from 'components/Home/BestSeller/BestSeller';
import Description from 'components/Home/Description/Description';

function Home() {
  const [testimonialData, setTestimonial] = useState([]);
  const [bestSellerData, setBestSeller] = useState([]);
  const [partnershipData, setPartnership] = useState([]);

  const getData = async () => {
    const { data } = await axios.get('/testimonial/loadData');

    setTestimonial(data[0]);
    setBestSeller(data[1]);
    setPartnership(data[2]);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Description />
      <BestSeller bestSellerData={bestSellerData} />
      <Welcome />
      <Testimonial testimonialData={testimonialData} />
      <Subscription />
      <Partnerships partnershipData={partnershipData} />
    </>
  );
}

export default Home;
