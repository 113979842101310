import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default function FieldButtons({ label, fontcolor,buttonpadding, buttoncolor, buttonborder, hoverbgcolor, disabled, onClick }) {
  const useStyles = makeStyles(() => ({
    fieldButton: {
      color: fontcolor,
      backgroundColor: buttoncolor,
      border: buttonborder,
      padding: buttonpadding,
      textTransform: 'capitalize',

      '&:hover': {
        backgroundColor: hoverbgcolor,
    },
    },
  }));
  const classes = useStyles();
  return (
    <div>
      {/* <button
        style={{
          color: fontcolor,
          padding: buttonpadding,
          backgroundColor: buttoncolor,
          border: buttonborder,
          height: buttonheight,
          textTransform: 'capitalize',
        }}
      >{label}</button> */}
      <Button className={classes.fieldButton}
      variant='contained'
      disabled={disabled}
      type='submit'
      onClick={onClick}
      >
        {label}
      </Button>
    </div>
  )
}
