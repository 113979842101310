import EmptyCart from "components/ShoppingCart/EmptyCart";

function Empty() {
  return (
    <div>
      <EmptyCart />
    </div>
  );
}

export default Empty;
