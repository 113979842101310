import React from 'react';
import Grid from '@mui/material/Grid';
import '../Testimonial/testimonial.css';
import inverted1 from '../images/inverted1.svg';
import inverted2 from '../images/inverted2.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Testimonial(Props) {
  const { testimonialData } = Props;

  return (
    <Grid container>
      {testimonialData.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30vh',
          }}
        >
          <CircularProgress color='success' />
        </Box>
      ) : (
        <Grid className='testimonial' container spacing={3}>
          {testimonialData.map((item, index) => (
            <Grid key={item.id} className='bio' item xs={12} md={4}>
              <div className='pharagraph'>
                <div className='com1'>
                  <img src={inverted1} alt='comma' />{' '}
                </div>
                <p className='pharagraphp'>{item.description}</p>
                <div className='com2'>
                  <img src={inverted2} alt='comma' />{' '}
                </div>
              </div>
              {index === 0 ? (
                <div className='boyImg'>
                  {' '}
                  <img src={item.image} alt='peopleImg' />{' '}
                </div>
              ) : index === 1 ? (
                <div className='boyImg'>
                  {' '}
                  <img src={item.image} alt='peopleImg' />{' '}
                </div>
              ) : (
                <div className='boyImg'>
                  {' '}
                  <img src={item.image} alt='peopleImg' />{' '}
                </div>
              )}

              <div className='name'>
                <h1 className='nameh4'>{item.name}</h1>
                <p className='namep'>{item.occupation}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
