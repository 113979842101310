import React from "react";
import PagePath from "../../components/common/pagePath/PagePath";
import "./privacyPolicyPage.css";
import { Helmet } from "react-helmet";

function PrivacyPolicyPage() {
  return (
    <div className="privacy">
      <Helmet>
        <title>Seafood Shop Eka - Privacy Policy Page</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      <div className="PrivacyText">
        <PagePath setp1={"Privacy PolicyPage"} className="pagepathinPrivacy" />
        <h1>Privacy Policy</h1>
      </div>
      <div className="backPP">
        <div className="mainPharagraphs">
          <span>Last updated: august 1, 2023</span>
          <p>
            This Privacy Statement informs You of Your privacy rights and the
            ways in which the law guards them while describing Our policies and
            practices regarding the gathering, use, and disclosure of Your
            information when You use the Service.To deliver and enhance the
            Service, we use Your Personal data. You accept that information will
            be gathered and used in line with this Privacy Policy if you use the
            Service. Utilizing the Privacy Policy Template, we were able to
            generate this privacy policy.
          </p>
          <p>
            {" "}
            The words whose first letter is capitalized have the following
            definitions for their meanings. No matter whether they are written
            in the singular or plural, the following meanings are to signify the
            same thing.
          </p>
          <h3>Collecting and Using Your Personal Data:</h3>
          <p>
            While using our service, We might ask you for some personally
            identifying information that will allow us to get in touch with you
            or identify you. Among other things, personally identifiable
            information could be:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Usage Data</li>
            <li>etc</li>
          </ul>
          <h3>For the purposes of this Privacy Policy:</h3>
          <ul className="unOdrList">
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Personal Data
              </span>{" "}
              is any information that relates to an identified or identifiable
              individual.
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Usage Data
              </span>{" "}
              refers to data collected automatically, either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>You </span>{" "}
              mean the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>Account</span>{" "}
              means a unique account created for You to access our order process
              and Services or parts of our Services
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>Company</span>{" "}
              (referred to as either "the Company", "We", "Us" or "Our" in this
              Agreement) refers to Seafood Shop Eka, Our address
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Service{" "}
              </span>{" "}
              refers to the Website.
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Service Provider
              </span>{" "}
              means any natural or legal person who processes the data on behalf
              of the Company. It refers to third-party companies or individuals
              employed by the Company to facilitate the Service, to provide the
              Service on behalf of the Company, to perform services related to
              the Service, or to assist the Company in analyzing how the Service
              is used
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Third-party Social Media Service{" "}
              </span>{" "}
              refers to any website or any social network website through which
              a User can log in or create an account to use the Service.
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Cookies{" "}
              </span>{" "}
              are small files that are placed on Your computer, mobile device,
              or any other device by a website, containing the details of Your
              browsing history on that website among its many uses.
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Country{" "}
              </span>{" "}
              refers to: the country that needs to receive your order • Need to
              add our forms data here
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>Device </span>{" "}
              means any device that can access the Service such as a computer, a
              cellphone, or a digital tablet.
            </li>
            <li>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                Website{" "}
              </span>{" "}
              means any device that can access the Service such as a computer, a
              cellphone, or a digital tablet.
            </li>
          </ul>
          <h5 style={{ lineHeight: "1.2rem" }}>
            Shop Eka <br />
            Attention: Privacy Compliance Officer <br />
            467/C,
            <br />
            Dadugama, <br />
            Ja-Ela. <br />
            <span>shop.eka.me@gmail.com</span>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
