// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/core/styles';
// import { Field, ErrorMessage } from "formik";

// import { TextField } from "@material-ui/core";

// export default function TextFeilds(label = "Email", name = "name",fullWidth, bgColor, inputwidth, textpadding) {
//     const useStyles = makeStyles(() => ({
//         text: {
//             backgroundColor: bgColor,
//             width: '100%',
//             border: '#FFFFFF',
//             padding: '0.5rem',
//             color: '#41630F',
//             '&:onclick': {
//                 border: '1px solid #41630F',
//             },
//         },
//     }));
//     // const cssTextField = withStyles((theme) => ({
//     //     root: {
//     //       '& label.Mui-focused': {
//     //         color: '#e45826',

//     //       },
//     //       '& .MuiInput-underline:after': {
//     //         borderBottomColor: '#e45826',

//     //       },
//     //       '& .MuiOutlinedInput-root': {
//     //         '& fieldset': {
//     //           border: '2px solid #e45826',
//     //           borderRadius: 60,
//     //           height: "50px",
//     //           fontSize: '1.3rem',
//     //           [theme.breakpoints.down('md')]: {
//     //             height: "50px",
//     //             fontSize: '1.2rem',
//     //           },
//     //           [theme.breakpoints.down('sm')]: {
//     //             height: "50px",
//     //             fontSize: '1.1rem',
//     //           },
//     //           [theme.breakpoints.down('xs')]: {
//     //             height: "45px",
//     //             fontSize: '1rem',
//     //           },

//     //         },
//     //         '&:hover fieldset': {
//     //           borderColor: '#e45826',
//     //         },
//     //         '&.Mui-focused fieldset': {
//     //           borderColor: '#e45826',

//     //         },
//     //       },
//     //     },
//     //   }))(TextField);
//     const classes = useStyles();
//     // const classes = cssTextField();
//     return (
//         <div>
//             <Field className={classes.text}
//                 //  component={CssTextField}
//                 label={label}
//                 variant="outlined"
//                 type="password"
//                 name={name}
//                 fullWidth
//                 required
//                 helperText={<ErrorMessage name={name}></ErrorMessage>}
//                 margin="dense"
//             ></Field>
//         </div>
//     )
// }
import React from "react";
import { Field, ErrorMessage } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import './Feild.css';

import { TextField } from "@material-ui/core";
export default function InputFeild({
  label = "Email",
  name = "name",
  bgColor,
  Placeholder,
  rows = 1,
  multiline,
  type = "text",
  height,
  disabled,
}) {
  const useStyles = makeStyles(() => ({
    text: {
      backgroundColor: bgColor,
      width: "100%",
      border: "none",
      multiline: multiline,
      height: height,
      // padding: '0.5rem',
      // color: "#41630F",
      "&:onclick": {
        border: "1px solid #41630F",
      },
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Field
        className={classes.text}
        as={TextField}
        // label={label}
        variant="outlined"
        placeholder={Placeholder}
        fullWidth
        name={name}
        helperText={
          <ErrorMessage className={classes.errormg} name={name}></ErrorMessage>
        }
        margin="dense"
        rows={rows}
        type={type}
        multiline={multiline}
        height={height}
        disabled={disabled}
      ></Field>
    </div>
  );
}
