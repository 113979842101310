import React, { useState } from "react";
import FieldButtons from "../FieldButtons";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import QuickView from "../../Home/BestSeller/CardView/DialogPop";
import "./itemcard.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addItem } from "../../../store/actions/cartActions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "../../../utils/lib/axios";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useMediaQuery from "@mui/material/useMediaQuery";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ItemCard({ item, favorite, loadData2 }) {
  const mobile = useMediaQuery("(max-width: 820px)");

  const [open, setOpen] = useState(false);
  const [cartopen, setCartpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setCartpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCartpen(false);
  };
  const dispatch = useDispatch();
  const [cardimgs, setCardimgs] = useState(true);

  const handleMouseOver = () => {
    setCardimgs(false);
  };

  const handleMouseOut = () => {
    setCardimgs(true);
  };
  let navigate = useNavigate();
  const handlePerOrder = () => {
    navigate("/preOrder", { state: item });
  };
  const gotoCart = () => {
    navigate("/ShoppingCart", { state: item });
  };

  const objWithIdIndex = favorite.findIndex((obj) => obj === item._id);

  const changeFavorite = async (id) => {
    await axios.post("/user/changeFavorite", { favorite: id });
    loadData2();
  };
  return (
    <div>
      {item.status === "active" && (
        <div className="itemcard">
          <div className="relative">
            <div className="imgdiv">
              {item.status === "active" && <QuickView item={item} />}
              {/* <button className='itemicondiv'><VisibilityIcon className='itemicon' fontSize="small" /></button> */}
              {objWithIdIndex >= 0 ? (
                <button className="itemicondiv">
                  <FavoriteIcon
                    className="itemicon"
                    fontSize="small"
                    onClick={() => changeFavorite(item._id)}
                  />
                </button>
              ) : (
                <button className="itemicondiv">
                  <FavoriteBorderIcon
                    className="itemicon"
                    fontSize="small"
                    onClick={() => changeFavorite(item._id)}
                  />
                </button>
              )}
            </div>
            <div>
              <img
                src={cardimgs ? item.image1 : item.image2}
                alt="cardImage"
                className="imgcarddiv"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              />
            </div>
          </div>
          <div className="itemcard2">
            {item.weight > 1000 ? (
              <p className="itemweight">
                {item.subName} ({item.weight / 1000} Kg) portions
              </p>
            ) : (
              <p className="itemweight">
                {item.subName} ({item.weight}{" "}
                {item.subName !== "Eggs (බිත්තර)" && "g"}) portions
              </p>
            )}
            <p className="categoryname">Category: {item.category[0].name}</p>
            <p className="categorystore">Store: Seafood Shop Eka</p>
            <p className="itemprice">LKR {item.price}</p>
            <FieldButtons
              label="Add To Bag"
              fontcolor="#FFFFFF"
              buttoncolor="#41630F"
              buttonborder="none"
              hoverbgcolor="#41630F"
              onClick={() => {
                item.fQuantity = 1;
                dispatch(addItem(item));
                handleClick();
              }}
            />
            {mobile && (
              <Snackbar
                open={cartopen}
                onClose={alertClose}
                style={{ fontSize: "1rem", alignItems: "center" }}
              >
                <Alert
                  onClose={alertClose}
                  severity="success"
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={gotoCart}
                  >
                    <ShoppingCartIcon
                      style={{ fontSize: "1.1rem", padding: 0, margin: 0 }}
                    />{" "}
                    <p style={{ padding: "0 0 0 0.5rem", margin: 0 }}>
                      Go to cart!
                    </p>
                  </div>
                </Alert>
              </Snackbar>
            )}
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Your Item Added to Bag!
              </Alert>
            </Snackbar>
          </div>
        </div>
      )}
      {item.status === "inactive" && (
        <div className="itemcard">
          <div className="relative">
            <div className="imgdiv2">
              <div>
                {item.status === "inactive" && (
                  <p onClick={handlePerOrder} className="iconp">
                    <div className="itemicondiv">
                      <VisibilityIcon className="icn" fontSize="small" />
                    </div>
                  </p>
                )}
                {/* <button className='itemicondiv'><VisibilityIcon className='itemicon' fontSize="small" /></button> */}
                <button className="itemicondiv">
                  <FavoriteBorderIcon className="itemicon" fontSize="small" />
                </button>
              </div>
              <div className="stockbtn">
                <FieldButtons
                  label="Out Of Stock"
                  fontcolor="#FFFFFF"
                  buttoncolor="#B91E27"
                  buttonborder="none"
                  hoverbgcolor="#B91E27"
                />
              </div>
            </div>
            <div>
              <img
                src={cardimgs ? item.image1 : item.image2}
                alt="cardImage"
                className="imgcarddiv2"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              />
            </div>
          </div>
          <div className="itemcard2">
            <p className="itemweight">{item.subName}</p>
            <p className="categoryname">Category: {item.category[0].name}</p>
            <p className="categorystore">Store: Seafood Shop Eka</p>
            <p className="itemprice">LKR {item.price}</p>
            <FieldButtons
              label="Per Order"
              fontcolor="#FFFFFF"
              buttoncolor="#393E40"
              buttonborder="none"
              hoverbgcolor="#393E40"
              onClick={() => {
                handlePerOrder();
              }}
            />
            {mobile && (
              <Snackbar
                open={cartopen}
                onClose={alertClose}
                style={{ fontSize: "1rem", alignItems: "center" }}
              >
                <Alert
                  onClose={alertClose}
                  severity="success"
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={gotoCart}
                  >
                    <ShoppingCartIcon
                      style={{ fontSize: "1.1rem", padding: 0, margin: 0 }}
                    />{" "}
                    <p style={{ padding: "0 0 0 0.5rem", margin: 0 }}>
                      Go to cart!
                    </p>
                  </div>
                </Alert>
              </Snackbar>
            )}
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Your Item Added to Bag!
              </Alert>
            </Snackbar>
          </div>
        </div>
      )}
    </div>
  );
}
