import React from "react";
import "../Footer/footer.css";
import { useNavigate } from "react-router-dom";
// import { Grid } from "@mui/material";

function Footer() {
  let navigate = useNavigate();
  const PrivacyPolicyPage = () => {
    navigate("/privacyPolicy");
  };
  const Product = () => {
    navigate("/products");
  };
  const Viewcard = () => {
    navigate("/shoppingCart");
  };
  const Login = () => {
    navigate("/login");
  };
  const MyAccount = () => {
    navigate("/dashboard");
  };
  const TermsConditions = () => {
    navigate("/terms-conditions");
  };
  return (
    <>
      <div className="mainfdiv">
        <div className="submainfdiv">
          <div className="subonediv">
            <div className="subonediv2">
              <p className="footerTextP"> 467/C, Dadugama, Ja-Ela</p>
              <p className="footerTextP"> Hotline: 0777631108/ 070 339 0432</p>
              <p className="footerTextP">Email: shop.eka.me@gmail.com</p>
            </div>
          </div>
          <div className="subonediv">
            <div className="subitemdiv">
              <h2 className="title"> LEARN MORE </h2>
              <p className="footernavigate" onClick={TermsConditions}>
                Terms & conditions
              </p>
              <p className="footernavigate" onClick={PrivacyPolicyPage}>
                Privacy Policy
              </p>
            </div>
            <div className="subitemdiv">
              <h2 className="title">INFORMATION </h2>
              <p className="footernavigate" onClick={Product}>
                Products
              </p>
            </div>
            <div className="subitemdiv">
              <h2 className="title"> ACCOUNT </h2>
              <p className="footernavigate" onClick={MyAccount}>
                My Account
              </p>
              <p className="footernavigate" onClick={Login}>
                Login
              </p>
              <p className="footernavigate" onClick={Viewcard}>
                View Cart
              </p>
            </div>
          </div>
        </div>
        <div className="submainfdiv2">
          <p className="submainfdiv2p">Concept and Design by Q4C</p>
        </div>
      </div>

      {/* <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "#ECEFF1" }}
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
            margin: { lg: "3rem", md: "2.5rem", sm: "2rem", xs: "2rem" },
          }}
        >
          <Grid
            item
            lg={5.5}
            md={4.5}
            sm={5}
            xs={12}
            sx={{
              marginRight: { lg: "3.5rem", md: "4.5rem", sm: "1.5rem", xs: 0 },
              marginLeft: { lg: "3rem", md: "1.2rem", sm: "0", xs: 0 },
              textAlign: { lg: "left", md: "left", sm: "left", xs: "center" },
            }}
          >
            <p className="footerTextP">
              {" "}
              No. 135/1 Carmel Mawatha, Palliyawatta, Wattala
            </p>
            <p className="footerTextP"> Hotline: 076 197 3176</p>
            <p className="footerTextP">Email: shop.eka.me@gmail.com</p>
          </Grid>

          <Grid
            item
            lg={6.5}
            md={7.5}
            sm={7}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
              textAlign: { lg: "left", md: "left", sm: "left", xs: "center" },
            }}
          >
            <Grid item lg={12} md={12} sm={10.5} xs={12}>
              <h2 className="title"> LEARN MORE </h2>
              <p className="footernavigate">Terms & conditions</p>
              <p className="footernavigate" onClick={PrivacyPolicyPage}>
                Privacy Policy
              </p>
            </Grid>
            <Grid item lg={12} md={12} sm={11} xs={12}>
              <h2 className="title">INFORMATION </h2>
              <p className="footernavigate" onClick={Product}>
                Products
              </p>
            </Grid>
            <Grid item lg={9} md={9} sm={8} xs={12}>
              <h2 className="title"> ACCOUNT </h2>
              <p className="footernavigate" onClick={MyAccount}>
                My Account
              </p>
              <p className="footernavigate" onClick={Login}>
                Login
              </p>
              <p className="footernavigate" onClick={Viewcard}>
                View Cart
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="submainfdiv2">
        <p className="submainfdiv2p">Concept and Design by Q4C</p>
      </div> */}
    </>
  );
}

export default Footer;
