import React from 'react';
import clogo from '../images/c1.png';
import './company.css'

export default function Company() {
    return (
        <div className='logomain'>
            <div className='logomain2'>
                <div className='logomain4'>
                    <img src={clogo} alt='logo' className='logomain3' />
                </div>
                <div className='logomain4'>
                    <img src={clogo} alt='logo' className='logomain3' />
                </div>
                <div className='logomain4'>
                    <img src={clogo} alt='logo' className='logomain3' />
                </div>
                <div className='logomain4'>
                    <img src={clogo} alt='logo' className='logomain3' />
                </div>
                <div className='logomain4'>
                    <img src={clogo} alt='logo' className='logomain3' />
                </div>
            </div>
        </div>
    )
}
