import React,{ useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import axios from '../../../utils/lib/axios';
import OpenOrder from "./OpenOrder";

const columns = [
  { id: 'date', label: 'DATE', minWidth: 170 },
  { id: 'totalPrice', label: 'TOTAL PRICE', minWidth: 170 },
  {
    id: 'order',
    label: 'ORDER',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'confirmOrder',
    label: 'CONFIRM ORDER',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable() {

    const [order, setOrder] = useState([]);
    const rows = order;

    const loadData = async () => {
        const {data} = await axios.get("/order/myOrders");
        setOrder(data);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);


  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  // style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const getTotal = () => {
                  let total = 0;
                  row.itemList.map((item) => {
                      total = total + item.price * item.fQuantity;
                      return total;
                  })
                
                  return total;
                }
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell>{row.updatedAt.split("T")[0]}</TableCell>
                  <TableCell>{getTotal()}</TableCell>
                  <TableCell key="itemList">
                    <OpenOrder itemList={row.itemList} />
                  </TableCell>
                  <TableCell>
                    {row.status === "active" ? <p>Confirm</p> : <p>Pending</p>}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
