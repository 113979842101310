import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {removeItem} from "../../store/actions/cartActions";
import {useDispatch} from "react-redux";


function Quantity({quant, tCart, id, setTCart}) {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(quant);
    const changeQuantity = (value) => {
        tCart.find(item => item._id === id).fQuantity = Number(value);
        setTCart([...tCart])
        dispatch(removeItem([...tCart]));
    }
    return (
      <>
        <TextField
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
            changeQuantity(e.target.value);
          }}
          id="outlined-basic"
          variant="outlined"
          type="number"
          inputProps={{ min: 0 }}
        />
      </>
    );
}

export default Quantity;
