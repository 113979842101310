import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Grid, CardContent } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Formik, Form } from "formik";
import InputFeild from "../../components/common/TextFeilds";
import FieldButtons from "../../components/common/FieldButtons";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import "./requestQuote.css";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';


export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="coming soon" placement="top"><li onClick={handleClickOpen}>REQUEST A QUOTE</li></Tooltip>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={"lg"}>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container>
          <Formik>
            {({ dirty, isValid }) => {
              return (
                <Form className="formLPword">
                  <h3 className="requstTitle">Request A Quote</h3>
                  <CardContent className="requestForm">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <p>
                          {" "}
                          Email <span className="inputSpan">*</span>{" "}
                        </p>
                        <InputFeild
                          name="email"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p>
                          {" "}
                          Country <span className="inputSpan">*</span>{" "}
                        </p>
                        <InputFeild
                          name="country"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                    <p>
                      {" "}
                      Store <span className="inputSpan">*</span>{" "}
                    </p>
                    <InputFeild
                      name="store"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      margin="dense"
                    />
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <p>
                          {" "}
                          Product <span className="inputSpan">*</span>{" "}
                        </p>
                        <InputFeild
                          name="product"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <p>
                          {" "}
                          Quantity <span className="inputSpan">*</span>{" "}
                        </p>
                        <InputFeild
                          name="quantity"
                          bgColor="#FFFFFF"
                          margin="dense"
                          type="number"
                          inputProps={{ min: 0 }}
                          component={TextField}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={2}>
                        <RemoveCircleOutlineIcon
                          style={{ color: "#DC3545", marginTop: 65 }}
                        />
                        <AddCircleOutlineIcon />
                      </Grid>
                    </Grid>
                    <div className="LPWbtn">
                      <FieldButtons
                        label="Submit quote"
                        fontcolor="#FFFFFF"
                        buttoncolor="#41630F"
                        buttonborder="none"
                        hoverbgcolor="#41630F"
                      />
                    </div>
                  </CardContent>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Dialog>
    </div>
  );
}
