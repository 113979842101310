import React from 'react';
import Grid from '@mui/material/Grid';
import './bestSeller.css';
import SellerCard from './CardView/SellerCard';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useNavigate} from "react-router-dom";

export default function BestSeller(Props) {
  const {bestSellerData} = Props;
    let navigate = useNavigate();

    const goToProduct = () => {
        navigate("/products");
    };
  return (
    <Grid className='main'>
      <Grid className='rowText'>
        <h2>BEST SELLER</h2>
        <p onClick={goToProduct} className='showAll'>Show All Best Sells</p>
      </Grid>
      {bestSellerData.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30vh',
          }}
        >
          <CircularProgress color='success' />
        </Box>
      ) : (
        <Grid container spacing={3} direction='row' alignItems='center'>
          {bestSellerData.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={3} lg={3}>
              <SellerCard item={item} />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
