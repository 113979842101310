import React, { useState } from 'react';
import FieldButtons from '../../../common/FieldButtons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import '../../../common/ItemCard/itemcard.css';
import { useNavigate } from "react-router-dom";
import {addItem} from "../../../../store/actions/cartActions";
import {useDispatch} from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useMediaQuery from "@mui/material/useMediaQuery";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function ItemCard({ item }) {
    const mobile = useMediaQuery("(max-width: 820px)");

    let navigate = useNavigate();
    const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [cartopen, setCartpen] = useState(false);

    const handleClick = () => {
      setOpen(true);
      setCartpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const DialogPop = () => {
        navigate("/products");
  }
  
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCartpen(false);
  };
    const gotoCart = () => {
      navigate("/ShoppingCart", { state: item });
    };

    const [cardimgs, setCardimgs] = useState(true);

    const handleMouseOver = () => {
        setCardimgs(false)
    }

    const handleMouseOut = () => {
        setCardimgs(true)
    }

    return (
      <div className="itemcard">
        <div className="relative">
          <div className="imgdiv">
            {/* <QuickView item={item} /> */}
            <button className="itemicondiv" onClick={DialogPop}>
              <VisibilityIcon className="itemicon" fontSize="small" />
            </button>
            <button className="itemicondiv" onClick={DialogPop}>
              <FavoriteBorderIcon className="itemicon" fontSize="small" />
            </button>
          </div>
          <div>
            <img
              src={cardimgs ? item.image1 : item.image2}
              alt="cardImage"
              className="imgcarddiv"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            />
          </div>
        </div>
        <div className="itemcard2">
          <p className="itemweight">
            {item.subName} ({item.weight}g)portions
          </p>
          <p className="categoryname">
            Category:{" "}
            {item.category?.name ? item.category?.name : item.category[0]?.name}
          </p>
          <p className="categorystore">Store: Seafood Shop Eka</p>
          <p className="itemprice">LKR {item.price}</p>
          <FieldButtons
            label="Add To Bag"
            fontcolor="#FFFFFF"
            buttoncolor="#41630F"
            buttonborder="none"
            hoverbgcolor="#41630F"
            onClick={() => {
              item.fQuantity = 1;
              item.category = [item.category];
              dispatch(addItem(item));
              handleClick();
            }}
          />
          {mobile && (
            <Snackbar
              open={cartopen}
              onClose={alertClose}
              style={{ fontSize: "1rem", alignItems: "center" }}
            >
              <Alert
                onClose={alertClose}
                severity="success"
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={gotoCart}
                >
                  <ShoppingCartIcon
                    style={{ fontSize: "1.1rem", padding: 0, margin: 0 }}
                  />{" "}
                  <p style={{ padding: "0 0 0 0.5rem", margin: 0 }}>
                    Go to cart!
                  </p>
                </div>
              </Alert>
            </Snackbar>
          )}
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Your Item Added to Bag!
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
}
