import React, { useState } from "react";
import "./feedback.css";
import Grid from "@mui/material/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@mui/material/TextField";
import { Formik, Form } from "formik";
import InputFeild from "../../components/common/TextFeilds";
import * as Yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import OutlinedButtons from "../../components/common/OutlinedButtons";
import axios from "../../utils/lib/axios";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import PagePath from '../../components/common/pagePath/PagePath';
import {Helmet} from "react-helmet";


function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a feedback.");
}

function Feedback() {
  let initialValues = {
    feedbackTitle: "",
    email: "",
    description: "",
  };


  let feedbackSchema = Yup.object().shape({
    feedbackTitle: Yup.string().required("Feedback Title is required!"),
    email: Yup.string().required("Email is required!"),
    description: Yup.string(),
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      await axios.post("/feedback/", {
        name: "Text",
        title: e.feedbackTitle,
        email: e.email,
        feedbackType: selectedValue,
        description: e.description,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Feedback send successfully",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Feedback send successfully",
          }),
        5000
      );
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server error!",
            }),
          5000
        );
      }
    }
  };

  const [selectedValue, setSelectedValue] = React.useState('bug');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const CssRadio = withStyles({
    colorSecondary: {
      color: "#41630f9c",
      "&$checked": {
        color: "#41630F",
      },
    },
    checked: {},
  })(Radio);

  return (
    <div className="feedback_bg">
      <Helmet>
        <title>Seafood Shop Eka - Feedback Page</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      <div role="presentation" onClick={handleClick} className="feedPath">
        <PagePath setp1={"Feedback"} className="pagepathin" />
      </div>
      <Grid container className="feedBack">
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={feedbackSchema}
        >
          {({ dirty, isValid }) => {
            return (
              <Form className="feedForm">
                <p className="inputTextP"> Feedback Title </p>
                <InputFeild
                  name="feedbackTitle"
                  component={TextField}
                  bgColor="#FFFFFF"
                  variant
                  fullWidth
                  margin="dense"
                />
                <p className="inputTextP"> Email </p>
                <InputFeild
                  name="email"
                  component={TextField}
                  bgColor="#FFFFFF"
                  variant
                  fullWidth
                  margin="dense"
                />
                <RadioGroup
                  aria-label="feedBack"
                  name="feedBack"
                  className="feedRadioBtn"
                >
                  <div className="feedRadioBtn">
                    <FormControlLabel
                      checked={selectedValue === "bug"}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Bug" }}
                      value="bug"
                      control={<CssRadio />}
                      label="Bug"
                      className="radio"
                    />
                    <FormControlLabel
                      checked={selectedValue === "feature-request"}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Feature Request" }}
                      value="feature-request"
                      control={<CssRadio />}
                      label="Feature Request"
                      className="radioRequest"
                    />
                    <FormControlLabel
                      checked={selectedValue === "positive"}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Positive" }}
                      value="positive"
                      control={<CssRadio />}
                      label="Positive"
                      className="radio"
                    />
                    <FormControlLabel
                      checked={selectedValue === "negative"}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Negative" }}
                      value="negative"
                      control={<CssRadio />}
                      label="Negative"
                      className="radio"
                    />
                    <FormControlLabel
                      checked={selectedValue === "other"}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Other" }}
                      value="other"
                      control={<CssRadio />}
                      label="Other"
                      className="radio"
                    />
                  </div>
                </RadioGroup>
                <p className="inputTextP"> Description </p>
                <InputFeild
                  name="description"
                  component={TextField}
                  bgColor="#FFFFFF"
                  variant
                  fullWidth
                  margin="dense"
                  rows={4}
                />
                {/* <Field
                  name="description"
                  multiline
                  rows={4}
                /> */}
                <FormGroup>
                  <FormControlLabel
                    style={{ fontSize: "0.9rem" }}
                    control={<Checkbox defaultChecked color="success" />}
                    label="Contact me via Email"
                  />
                </FormGroup>
                <div className="feedBtn">
                  <div className="feedbackBtn">
                    <OutlinedButtons
                      label="Cancel"
                      fontcolor="#41630F"
                      buttonborder="solid 1px"
                      hoverbgcolor="#FFFFFF"
                      hoverfontcolor="#41630F"
                    />
                  </div>
                  <div className="feedbackBtn">
                    <OutlinedButtons
                      label="Send"
                      fontcolor="#41630F"
                      buttonborder="solid 1px "
                      hoverbgcolor="#41630F"
                      hoverfontcolor="#FFFFFF"
                      disabled={!dirty || !isValid}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {alert.showAlert && (
          <Grid item md={12} style={{ marginTop: "0.7rem" }}>
            <Alert
              severity={alert.severity}
              onClose={() =>
                setAlert({
                  ...alert,
                  showAlert: false,
                })
              }
            >
              {alert.message}
            </Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Feedback;
