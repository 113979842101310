import React from 'react';
import './emptyCart.css';
import emptyFish from '../../components/ShoppingCart/emptyFish.png';
import OutlinedButtons from '../../components/common/OutlinedButtons';
import { useNavigate } from 'react-router-dom';

function EmptyCart() {
  let navigate = useNavigate();
  const handlePerOrder = () => {
    navigate('/products');
  };
  return (
    <div className='EmptyCart'>
      <div className='emptyWL'>
        <img src={emptyFish} alt='emptyImg' />
        <h4 style={{ marginBottom: '1rem' }}>
          Your Wishlist is currently empty.
        </h4>
        <OutlinedButtons
          label='Continue Shopping'
          fontcolor='#FFFFFF'
          outline='#FFFFF'
          hoverBgcolor='#FFFFF'
          hoverfontcolor='#41630F'
          onClick={() => {
            handlePerOrder();
          }}
        />
      </div>
    </div>
  );
}

export default EmptyCart;
