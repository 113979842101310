import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import axios from "../../utils/lib/axios";
import { useNavigate } from "react-router-dom";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import FieldButtons from "components/common/FieldButtons";
import InputFeild from "components/common/TextFeilds";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
    color: "#393e40e0",
    fontSize: "1.15rem",
    fontWeight: "bold",
    marginTop: 0,
    fontFamily: '"Lato",sans-serif',
    margin: "0",
  },
  padding: {
    padding: theme.spacing(3),
    backgroundColor: "#F5F5F5",
    border: "none",
    width: "45vw",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
    },
  },
  password: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mainGrid: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    height: "90vh",
  },
  Wrapper: {
    backgroundColor: "#FFFFFF",
    margin: "0",
    padding: "0",
  },

  button: {
    backgroundColor: "#2cca5c",
    color: "white",

    "&:hover": {
      backgroundColor: "#2cca5c",
      color: "white",
    },
  },
  button2: {
    marginTop: "1rem",
    color: "#2cca5c",
  },
  lostpassword: {
    color: "#393E40",
    textTransform: "none",
    display: "flex",
    alignItems: "flex-start",

    "&:hover": {
      color: "#41630F",
      background: "none",
    },
  },
  accountbtn: {
    color: "#393E40",
    textTransform: "none",
    "&:hover": {
      background: "none",
    },
  },
  newaccount: {
    color: "#41630F",
  },
  fieldStar: {
    color: "red",
  },
  mainDiv: {
    alignContent: "center",
  },
  paddingLogin: {
    padding: "3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0 2.5rem",
    },
  },
  tittle: {
    textAlign: "center",
    color: "#393E40",
    fontSize: "1.56rem",
    fontWeight: "bold",
    marginTop: 0,
    fontFamily: '"Lato",sans-serif',
    margin: "0",
  },
  text: {
    backgroundColor:"#FFFFFF",
    width: "100%",
    border: "none",
    color: "#41630F",
    "&:onclick": {
      border: "1px solid #41630F",
    },
  },
}));

function LostPassword(props) {
  let initialValues = {
    email: "",
    newPassword: "",
    confirmPassword: "",
  };

  let PasswordSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
    newPassword: Yup.string()
      .matches(new RegExp(/^.{8,}$/), "At least 8 characters required!")
      .required("Password is required!"),
    confirmPassword: Yup.string()
      .required("Please confirm your password!")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Confirm password doesn't match with the new password!"
      ),
  });


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const classes = useStyles();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  let navigate = useNavigate();

  const submit = async (e) => {
    try {
      await axios.post("/user/fogotPassword", {
        email: e.email,
        password: e.newPassword,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Password changed successfully",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Password changed successfully",
          }),
        5000
      );
      navigate("/login");
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: true,
              severity: "error",
              message: "Unauthorized!",
            }),
          5000
        );
      } else if (error.response.status === 501) {
        setAlert({
          showAlert: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: true,
              severity: "error",
              message:
                "You are temporary block. Please contact your administrator!",
            }),
          5000
        );
      } else if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "User dosen't exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: true,
              severity: "error",
              message: "User dosen't exists!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: true,
              severity: "error",
              message: "Server error!",
            }),
          5000
        );
      }
    }
  };

  return (
    <Grid className={classes.mainGrid} spacing={2} p={1} mt={10}>
      <div className={classes.password}>
        <Grid item className={classes.paddingLogin} md={12}>
          <Card className={classes.padding} variant="outlined">
            <h1 className={classes.tittle}>Forgot password</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={submit}
              validationSchema={PasswordSchema}
            >
              {({ dirty, isValid }) => {
                return (
                  <Form>
                    <CardContent style={{ padding: 0, marginBottom: "1rem" }}>
                      <p className={classes.input}>
                        {" "}
                        Email <span className={classes.fieldStar}>*</span>{" "}
                      </p>
                      <InputFeild
                        name="email"
                        component={TextField}
                        bgColor="#FFFFFF"
                        variant
                        fullWidth
                        Placeholder="Email"
                        margin="dense"
                      />
                      <p className={classes.input}>
                        New Password{" "}
                        <span className={classes.fieldStar}>*</span>
                      </p>
                      <Field
                        fullWidth
                        name="newPassword"
                        placeholder="New Password"
                        required
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        as={TextField}
                        margin="dense"
                        helperText={
                          <ErrorMessage
                            className={classes.errormg}
                            name="newPassword"
                          ></ErrorMessage>
                        }
                        className={classes.text}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <p className={classes.input}>
                        Confirm Password{" "}
                        <span className={classes.fieldStar}>*</span>
                      </p>
                      <Field
                        fullWidth
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        required
                        type={showConfirmPassword ? "text" : "password"}
                        variant="outlined"
                        as={TextField}
                        margin="dense"
                        helperText={
                          <ErrorMessage
                            className={classes.errormg}
                            name="confirmPassword"
                          ></ErrorMessage>
                        }
                        className={classes.text}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickConfirmPassword}
                                onMouseDown={handleMouseConfirmPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CardContent>
                    <Grid container justifyContent="flex-start">
                      <Grid item>
                        <FieldButtons
                          label="Change Password"
                          fontcolor="#FFFFFF"
                          buttoncolor="#41630F"
                          buttonborder="none"
                          hoverbgcolor="#41630F"
                          disabled={!dirty || !isValid}
                          type="password"
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Card>
          {alert.showAlert && (
            <Grid item md={12} style={{ marginTop: "0.7rem" }}>
              <Alert
                severity={alert.severity}
                onClose={() =>
                  setAlert({
                    ...alert,
                    showAlert: false,
                  })
                }
              >
                {alert.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  );
}

export default LostPassword;
