import { ADDITEM, REMOVEITEM, RESETITEM } from './types.js';

export const addItem = (cart)=>(dispatch)=>{
    dispatch({
        type:ADDITEM,
        payload:{cart}
    })
}

export const removeItem = (cart)=>(dispatch)=>{
    dispatch({
        type:REMOVEITEM,
        payload:{cart}
    })
}

export const resetItems = () => ({ type: RESETITEM });
