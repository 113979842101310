import Error404 from "components/404page/Error404";

function Error404Page() {
  return (
    <div>
      <Error404 />
    </div>
  );
}

export default Error404Page;
