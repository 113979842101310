import React, { useState } from "react";
import "./inStock.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FieldButtons from "../../components/common/FieldButtons";
import OutlinedButtons from "../../components/common/OutlinedButtons";
import PlaceIcon from "@mui/icons-material/Place";
import RedeemIcon from "@mui/icons-material/Redeem";
import PaymentIcon from "@mui/icons-material/Payment";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { addItem } from "../../store/actions/cartActions";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from '@mui/material/Alert';
import PagePath from '../../components/common/pagePath/PagePath';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useMediaQuery from "@mui/material/useMediaQuery";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function InStock(props) {
  const { state } = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [open2, setOpen2] = React.useState(false);
  const [quantity, setQuantity] = useState(1);
  const cart = useSelector((state) => state.cart.cart);
  const objWithIdIndex = cart.findIndex((obj) => obj._id === state._id);
  const mobile = useMediaQuery("(max-width: 820px)");
    const [cartopen, setCartpen] = useState(false);
 const alertClose = (event, reason) => {
   if (reason === "clickaway") {
     return;
   }

   setCartpen(false);
  };
    const gotoCart = () => {
      navigate("/ShoppingCart");
    };

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    navigate("/products");
  };
  const directToCheckout = () => {
    navigate("/checkout");
  }
  const visitStore = () => {
    navigate("/viewStore");
  };

  const directToCart = () => {
    navigate("/ShoppingCart");
  }
  return (
    <div>
      <div className="pagepathin2">
        <div className="InStockMainpath">
          <PagePath setp1={"Product"} setp2={"Instok"} className="pagepathin" />
        </div>
        <div container className="InStockMain">
          <div className="leftdivIS">
            {/* <p className="Instockpath">
              Home / Products / Crabs /{" "}
              <span className="pathSpan">Premium Norwegian Salmon (300g)portions</span>
            </p> */}
            {objWithIdIndex >= 0 && (
              <div className="leftFirstGIS">
                <div className="greenLineIS"></div>
                <div className="conformDetailsIS">
                  <div className="conformIS">
                    <div className="pinIcnTextIS">
                      <OfflinePinIcon className="PinIconIS" />
                      <p className="pinIcnTextISp">
                        “ {state.subName} (300g) portions ” has been added to
                        your cart.
                      </p>
                    </div>
                    <div>
                      <OutlinedButtons
                        label="view Cart"
                        fontcolor="#000"
                        buttoncolor="#FFFFFF"
                        buttonborder="1px solid #393E40"
                        hoverfontcolor="#FFFFFF"
                        hoverbgcolor="#393E40"
                        onClick={directToCart}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="rowGinStock">
              <div className="leftSecondInS">
                <div className="ISrderImages2">
                  <div className="inmimg2">
                    <img src={state.image2} alt="dishImage" className="ISimg" />
                  </div>
                  <div className="inmimg2">
                    <img src={state.image3} alt="dishImage" className="ISimg" />
                  </div>
                  <div className="inmimg2">
                    <img src={state.image4} alt="dishImage" className="ISimg" />
                  </div>
                  <div className="inmimg2">
                    <img src={state.image5} alt="dishImage" className="ISimg" />
                  </div>
                </div>
                <div className="inmimg">
                  <img
                    src={state.image1}
                    alt="dishImage"
                    className="mainISimg"
                  />
                </div>
              </div>
              <div className="leftThirdInS">
                <div className="ISname">
                  <h4>
                    {state.subName}
                    <br />({state.weight}g)portions
                  </h4>
                  <p className="GreenTextIS">
                    <span className="GreenTextIS">In-Stock</span>
                  </p>
                </div>
                <div className="IScategory">
                  <p className="InSsub">
                    Category: <span>{state.category[0].name}</span>
                  </p>
                </div>
                <div>
                  <hr />
                </div>
                <h3 className="greenTextIS">
                  <span>LKR {state.price}.00</span>
                </h3>
                <div className="QuantityIS">
                  <p>Qty:</p>
                  <div className="QtyformIS">
                    <TextField
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </div>
                </div>
                <div className="ISfavourite">
                  <div className="iconWidthInS">
                    <FavoriteBorderIcon className="IconInS" />
                  </div>
                  <p>Add to Favorites</p>
                </div>
                <div className="inStockBtns">
                  <FieldButtons
                    label="Buy Now"
                    fontcolor="#FFFFFF"
                    buttoncolor="#393E40"
                    buttonborder="none"
                    hoverbgcolor="#393E40"
                    onClick={() => {
                      state.fQuantity = Number(quantity);
                      dispatch(addItem(state));
                      directToCheckout();
                    }}
                  />
                  <FieldButtons
                    label="Add to Bag"
                    fontcolor="#FFFFFF"
                    buttoncolor="#41630F"
                    buttonborder="none"
                    hoverbgcolor="#41630F"
                    onClick={() => {
                      state.fQuantity = Number(quantity);
                      dispatch(addItem(state));
                      handleClick();
                    }}
                  />
                </div>
                {mobile && (
                  <Snackbar
                    open={cartopen}
                    onClose={alertClose}
                    style={{ fontSize: "1rem", alignItems: "center" }}
                  >
                    <Alert
                      onClose={alertClose}
                      severity="success"
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={gotoCart}
                      >
                        <ShoppingCartIcon
                          style={{ fontSize: "1.1rem", padding: 0, margin: 0 }}
                        />{" "}
                        <p style={{ padding: "0 0 0 0.5rem", margin: 0 }}>
                          Go to cart!
                        </p>
                      </div>
                    </Alert>
                  </Snackbar>
                )}
                <Snackbar
                  open={open2}
                  autoHideDuration={6000}
                  onClose={handleClose2}
                >
                  <Alert
                    onClose={handleClose2}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Your Item Added to Bag!
                  </Alert>
                </Snackbar>
              </div>
            </div>
            <div className="bDescription">
              <hr />
              <h4>Description</h4>
              <p>{state.description}</p>
            </div>
          </div>
          <div className="rightdivInS">
            <div className="rightBg-colorInS">
              <div className="right-DescriptinInS">
                <p className="deliveryh4InS">Delivery</p>
                <div className="dLocationIS">
                  <div className="iconWidthInS">
                    <PlaceIcon className="IconInS" />
                  </div>
                  <p className="maint">Colombo</p>
                </div>
                <hr />
                <div className="deliveryDetailInS">
                  <div className="iconWidthInS">
                    <RedeemIcon className="IconInS2" />
                  </div>
                  <div className="deliverySentenceInS">
                    <p className="maint">Express Delivery</p>
                    <p className="subt">
                      We deliver Mon-Sat, expect bank holidays. During high
                      season and sale, delivery might take a little longer.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="deliveryPaymentInS">
                  <div className="deliFlex">
                    <div className="iconWidthInS">
                      <PaymentIcon className="IconInS2" />
                    </div>
                    <div>
                      <p className="maint">Safe payments avaliable</p>
                      <p className="subt">Pay after delivery, Cash.</p>
                    </div>
                  </div>
                  <div>
                    <ErrorOutlineIcon className="IconInS2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="storeDetailsInS mgbottom">
              <div className="right-DescriptinInS">
                <p className="deliveryh4InS">Sold by</p>
                <div className="storeTextInS">
                  <p className="maint">Store Name: Seafood Shop-Eka</p>
                  <p>
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        visitStore();
                      }}
                    >
                      <span className="storeInS subt">Visit Store</span>
                    </button>
                  </p>
                </div>
                <p className="deliveryh4InS">Delivery Hotline : +94761973176</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InStock;
