import React, { useState } from "react";
import "../Subscription/subscription.css";
import * as Yup from "yup";
import { Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import FieldButtons from "../../common/FieldButtons";
import axios from "../../../utils/lib/axios";
import Alert from '@material-ui/lab/Alert';
import InputFeild from '../../common/TextFeilds';


export default function Subscription() {
  let initialValues = {
    phoneNumber: "",
  };

    const phoneRegExp = /^(?:7|0|(?:\+94))[0-9]{9,11}$/;

  let Validation = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required!"),
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      await axios.post("/subscriptionEmail/", {
        email: e.phoneNumber,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Phone number was added successfully",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Phone number was added successfully",
          }),
        5000
      );
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
        setTimeout(() => setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Unauthorized!',
        }), 5000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(() => setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Server error!',
        }), 5000);
      }
    }
  };

  return (
    <div className="Subscribe">
      <div className="subsDetail">
        <div className="subAlign">
          <h3 className="subsDetailh3">Don't miss these great deals!</h3>
          <div className="disText">
            <h2>Special Offers For Our Whatsapp Group Members</h2>
          </div>
          <div className="data">
            <div className="form">
              <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={Validation}
              >
                {({ dirty, isValid }) => {
                  return (
                    <Form className="email-form">
                      <div className="mailfield">
                        <InputFeild
                          name="phoneNumber"
                          component={TextField}
                          variant
                          fullWidth
                          bgColor="none"
                          Placeholder="Phone Number"
                          margin="dense"
                        />
                      </div>

                      <div style={{ marginTop: "8px" }}>
                        <FieldButtons
                          label="Subscribe Now"
                          fontcolor="#FFFFFF"
                          buttoncolor="#393E40"
                          buttonborder="none"
                          hoverbgcolor="#393E40"
                          // disabled={!dirty || !isValid}
                          type="submit"
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              {alert.showAlert && (
                <Grid item md={12} style={{marginTop:"0.7rem"}}>
                  <Alert
                    severity={alert.severity}
                    onClose={() =>
                      setAlert({
                        ...alert,
                        showAlert: false,
                      })
                    }
                  >
                    {alert.message}
                  </Alert>
                </Grid>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
