import * as React from 'react';
import './stepper.css';

export default function VerticalLinearStepper() {
  return (
    <div>
      <div className='stepline2div'>
        <div className='stepline3'></div>
      </div>
      <div className='stepline2div'>
        <div className='stepline2'></div>
        <div className='stepline'></div>
      </div>
      <div className='step'>
        <div className='cirnum'>&nbsp;1</div>
        <p className='Lorem'>Higher Quality</p>
      </div>
      <div className='stepline2div'>
        <div className='stepline2'></div>
        <div className='stepline'></div>
      </div>
      <div className='step'>
        <div className='cirnum'>&nbsp;2</div>
        <p className='Lorem'>Better Services</p>
      </div>
      <div className='stepline2div'>
        <div className='stepline2'></div>
        <div className='stepline'></div>
      </div>
      <div className='step'>
        <div className='cirnum'>&nbsp;3</div>
        <p className='Lorem'>Fresh products</p>
      </div>
      <div className='stepline2div'>
        <div className='stepline2'></div>
        <div className='stepline'></div>
      </div>
      <div className='step'>
        <div className='cirnum'>&nbsp;4</div>
        <p className='Lorem'>Speed Delivery</p>
      </div>
      <div>
        <div className='stepline2div'>
          <div className='stepline2'></div>
          <div className='stepline'></div>
        </div>
        <div className='stepline3'></div>
      </div>
    </div>
  );
}
