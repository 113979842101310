import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

// import Drawer from './components/layouts/Drawer';
import '../src/index.css';

import { Provider as ReduxProvider } from 'react-redux';
import store from './store/store';
import Loader from './components/UI/Loader';

import purple from '@material-ui/core/colors/purple';

// import Login from 'components/layouts/Login';
import NotFound from 'components/layouts/PageNotFound';
import PermissionDenied from 'components/layouts/PermissionDenied';
import SignUp from 'components/layouts/SignUp';
import Login from 'components/layouts/Login';
import ForgotPassword from 'components/layouts/ForgotPassword';

import Auth from 'components/common/Auth';
import Home from 'screens/Home';
import NavBar from 'components/Home/Navigation/NavBar';
import Company from 'components/Home/Company/Company';
import Footer from 'components/Home/Footer/Footer';
import ItemCard from 'components/common/ItemCard/ItemCard';

import Products from 'screens/products/Products';
import ThankYou from 'screens/ThankYou/ThankYou';
import PrivacyPolicy from 'screens/Privacy&Policy/PrivacyPolicy';
import TermsConditions from 'components/Terms & conditions/TermsConditions';
import ShoppingCarts from 'screens/ShoppingCart/ShoppingCarts';
import EmptyCarts from 'screens/ShoppingCart/EmptyCarts';
import Error404Page from 'screens/404Page/Error404Page';
import ViewStore from 'screens/ViewStore/ViewStore';
import Checkout from 'screens/Checkout/Checkout';
import PreOrder from 'screens/PreOrder/PreOrder';
import InStock from 'screens/InStock/InStock';
import Feedback from 'screens/feedback/Feedback';
import LostPassword from 'screens/lostPassWord/LostPassword';
import MyWishlist from 'screens/myWishlist/MyWishlist';
import PagePath from 'components/common/pagePath/PagePath';
import ChatButton from 'screens/chatButton/ChatButton';


import SideView from 'screens/products/sideView/SideView';
import ReactGA from "react-ga4";

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    secondary: {
      main: purple[500],
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  self: {
    backgroundColor: 'blue',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App(props) {
  const classes = useStyles();
  const TRACKING_ID = 'G-8MW4V8XN0B';
  ReactGA.initialize(TRACKING_ID);

  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: `${document.location.pathname}` });

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={lightTheme}>
        <div className={classes.root}>
          <BrowserRouter>
            <NavBar {...props}> </NavBar>
            <ChatButton />
            {/* <CartButton /> */}

            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Auth>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/permission-denied"
                    element={<PermissionDenied />}
                  />
                  <Route component={NotFound}></Route>
                  <Route path="/register" element={<SignUp />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/thankYou" element={<ThankYou />} />
                  <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-conditions"
                    element={<TermsConditions />}
                  />
                  <Route path="/passwordhelp" element={<ForgotPassword />} />
                  <Route path="/itemcard" element={<ItemCard />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/shoppingCart" element={<ShoppingCarts />} />
                  <Route path="/emptyCart" element={<EmptyCarts />} />
                  <Route path="/error404Page" element={<Error404Page />} />
                  <Route path="/viewStore" element={<ViewStore />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/preOrder" element={<PreOrder />} />
                  <Route path="/inStock" element={<InStock />} />
                  <Route path="/dashboard" element={<MyWishlist />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/lostPassword" element={<LostPassword />} />
                  <Route path="/Pagepath" element={<PagePath />} />
                  <Route path="/company" element={<Company />} />
                  <Route path="/sideView" element={<SideView />} />
                </Routes>
              </Auth>
            </Suspense>
            <Footer />
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
