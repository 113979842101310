import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

export default function OutlinedButtons({ onClick, label, buttonpadding, fontcolor, buttonborder, buttoncolor, hoverbgcolor, hoverfontcolor, disabled }) {
    const useStyles = makeStyles(() => ({
        outlinedbutton: {
            color: fontcolor,
            backgroundColor: buttoncolor,
            border: buttonborder,
            textTransform: 'capitalize',
            padding: buttonpadding,

            '&:hover': {
                backgroundColor: hoverbgcolor,
                color: hoverfontcolor,
            },
        },
    }));
    const classes = useStyles();

    return (
        <div>
            {/* type 2 */}
            <Button className={classes.outlinedbutton}
                variant="none"
                disabled={disabled}
                type='submit'
                onClick={onClick}
                >
                {label}
            </Button>
        </div>
    )
}
