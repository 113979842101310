import React from "react";
import "./emptyCart.css";
import emptyFish from "./emptyFish.png";
import OutlinedButtons from "../common/OutlinedButtons";
import PagePath from '../../components/common/pagePath/PagePath';
import { useNavigate } from "react-router-dom";


function EmptyCart() {
  let navigate = useNavigate();
    const handlePerOrder = () => {
        navigate("/products");
    }
  return (
    <div className="EmptyCart">
      <p className="pathP">
        <PagePath setp1={"Shopping Cart"} className='pagepathin' />
      </p>
      <div className="empty">
        <img src={emptyFish} alt="emptyImg" />
        <h3>Your cart is currently empty.</h3>
        <OutlinedButtons
          label="Continue Shopping"
          fontcolor="#FFFFFF"
          outline="#FFFFF"
          hoverBgcolor="#FFFFF"
          hoverfontcolor="#41630F"
          onClick={() => {
            handlePerOrder();
        }}
        />
      </div>
    </div>
  );
}

export default EmptyCart;
