import React from 'react';
import '../Welcome/welcome.css';
import Stepper from './Stepper/Stepper';

export default function Welcome() {
  return (
    // <div className='welcome'>
    //   <div className='introAndStepper'>
    //     <div className='align'>
    //       <div className='introduction'>
    //           <h3> WELCOME TO <span>SEAFOOD</span></h3>
    //           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu pariatur.</p>
    //           <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    //       </div>
    //       <div className='steppe'>
    //           <Stepper />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="welcome">
      <div className="introAndStepper">
        <div className="introduction">
          <h3 className="introductionh">
            {" "}
            WELCOME TO <span className="introductionh2">SEAFOOD SHOP EKA</span>
          </h3>
          <p>
            Seafood Shop Eka is a leading seafood market place in Sri Lanka with
            fresh seafood delivery!. Fresh Harvest caters to the local market in
            Sri Lanka and supplies high quality seafood products to top class
            hotel chains, leading restaurants and currently in expansion with
            the local retail market in Sri Lanka.The company also acts as an
            importer of high quality seafood products which is now becoming a
            popular demand amongst retail customers in Sri Lanka.
          </p>
          <p>
            {" "}
            The business also imports high-quality seafood items, which are
            increasingly in demand from Sri Lankan retail customers. In order to
            provide you with greater convenience and top-notch service, we are
            currently pleased to introduce our newest online shopping cart. We
            warmly welcome you and hope you enjoy this platform of bringing high
            quality, ready to cook, hassle free, door step delivery seafood
            service to make your life easier!
          </p>
        </div>
        <div className="steppe">
          <Stepper />
        </div>
      </div>
    </div>
  );
}
