import React, {useEffect, useState} from "react";
import "./shoppingCart.css";
import ClearIcon from "@mui/icons-material/Clear";
// import FieldButtons from "../common/FieldButtons";
import OutlinedButtons from "../common/OutlinedButtons";
import {  useSelector } from "react-redux";
import EmptyCart from "./EmptyCart";
import { useDispatch } from "react-redux";
import { removeItem} from "../../store/actions/cartActions";
import { useNavigate } from "react-router-dom";
import PagePath from '../../components/common/pagePath/PagePath';
import Quantity from './Quantity';


function ShoppingCart() {
  const cart = useSelector((state) => state.cart.cart);
  const [tCart, setTCart] = useState([...cart]);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const home = () => {
    navigate("/products");
  }
  const checkout  = () => {
    navigate("/checkout");
  }
  const getTotal = () => {
    let total = 0;
    cart.map((item) => {
         total = total + item.price * item.fQuantity;
         return total;
        })

    return total;
}
  const removeItems = (id) => {
    const objWithIdIndex = tCart.findIndex((obj) => obj._id === id);
    tCart.splice(objWithIdIndex, 1);
    setTCart([...tCart])
    dispatch(removeItem([...tCart]));
  }
  useEffect(()=>{
    setTCart(cart);
    // eslint-disable-next-line
  },[])
  if(tCart.length === 0){
    return (
        <EmptyCart />
    )
  }
  return (
    <div className="ShoppingCart">
      <div className="headding">
          <PagePath setp1={"Shopping Cart"} className='pagepathin' />
        <h2>Shopping Cart ({cart.length} Item)</h2>
      </div>

      <div className="tables">
        <div className="tableOverFlow">
          <table className="firstTable">
            <div className="tblMedia">
              <tr className="fstRow">
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Category</th>
                <th>Quantity</th>
                <th>Sub Total</th>
                <th>Action</th>
              </tr>
              {tCart.map((item)=> (
                  <tr className="scndRow" key={item._id}>
                    <td>
                      <img
                          src={item.image1}
                          alt="productImage"
                          style={{width:'3rem', height:'3rem'}}
                      />
                    </td>
                    <td>
                      <p>
                        <span>{item.subName} ({item.weight}g) portions</span>
                      </p>
                    </td>
                    <td>LKR {item.price}.00</td>
                    <td>{item.category[0].name}</td>
                    <td style={{width: '80px'}}>   <Quantity quant={item.fQuantity} tCart={tCart} id={item._id} setTCart={setTCart}/></td>
                    <td>LKR {item.price * item.fQuantity}.00</td>
                    <td>
                      <ClearIcon className="clearIcn"  onClick={()=> {
                        removeItems(item._id)
                      }}/>
                    </td>
                  </tr>
              ))}

            </div>
          </table>
        </div>


        <div className="secondTable">
          <div className="alignTbl">
            <h2 className='alignTblh2'>Cart totals</h2>
            <div className="tblRow">
              <h4>Sub Total</h4>
              <p>LKR. {getTotal()}.00</p>
            </div>
            <div className="tblRow2">
              <h4>Total</h4>
              <p>LKR. {getTotal()}.00</p>

            </div>
            <div className="buttns">
              <div className="btns">
                <OutlinedButtons
                  label="Back to shop"
                  fontcolor="#41630F"
                  buttonborder="solid 1px"
                  hoverbgcolor="#FFFFFF"
                  hoverfontcolor="#41630F"
                  onClick={home}
                />
                <div className='space'></div>
                <OutlinedButtons
                  label="Ceckout"
                  fontcolor="#41630F"
                  buttonborder="solid 1px"
                  hoverbgcolor="#41630F"
                  hoverfontcolor="#FFFFFF"
                  onClick={checkout}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingCart;
