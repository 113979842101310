import React, {useState} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Grid, CardContent } from "@material-ui/core";
import FieldButtons from "../../../components/common/FieldButtons";
import OutlinedButtons from "../../../components/common/OutlinedButtons";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./sideView.css";
import fishP1 from "../../../components/Home/images/fishP1.webp"
import TextField from '@mui/material/TextField';

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    right: false,
  });

  const [quantity, setQuantity] = useState(1);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 360 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ backgroundColor: "#F5F5F5" }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className="blackBar">
          Shopping Cart
          <HighlightOffIcon />
        </div>

        <CardContent>
          <div className="sideFirstRow">
            <img
              src={fishP1}
              alt="productImage"
              width="26%"
              className="sideImage"
            />
            <div className="svText">
              <h4 className="nameTextSV">
                Premium Norwegian Salmon (300g)portions
              </h4>
              <h4 className="priceSV">LKR 588.00</h4>
              <div className="QtyformSV">
                <TextField
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 0 }}
                />
              </div>
            </div>
            <p className="removeText">
              <u>Remove</u>
            </p>
          </div>
          <hr />
          <div className="textSV">
            <p>Subtotal</p>
            <p>LKR 588.00</p>
          </div>
          <div className="textSV">
            <p>Shipping</p>
            <p>--</p>
          </div>
          <hr />
          <div className="textSV">
            <h4 className="totalTextSV">TOTAL</h4>
            <h4 className="totalPriceSV">LKR 588.00</h4>
          </div>
          <div className="SVcontactDetails">
            <p>
              May we help you? To address any concerns please call us at 071
              1234566.
            </p>
          </div>
          <div className="buttonSV">
            <OutlinedButtons
              label="View cart"
              fontcolor="#41630F"
              buttonborder="solid 1px"
              hoverbgcolor="#41630F"
              hoverfontcolor="#ffffff"
            />
            <FieldButtons
              label="Checkout"
              fontcolor="#FFFFFF"
              buttoncolor="#41630F"
              buttonborder="none"
              hoverbgcolor="#41630F"
            />
          </div>
        </CardContent>
      </Grid>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
