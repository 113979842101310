import React, { useState, useEffect } from 'react'
import '../Navigation/navBar.css';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchIcon from '@mui/icons-material/Search';
import AddCommentIcon from '@mui/icons-material/AddComment';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import RequestQuote from '../../../screens/requestQuote/RequestQuote';
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useLocation } from "react-router-dom";
import logo from "../images/logo.jpg";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import Tooltip from '@mui/material/Tooltip';
import ProfilePopup from './profilePopup';
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/authActions";



function NavBar() {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scrollTo({ top: 0 });
    // scroll to the top of the browser window when changing route
    // the window object is a normal DOM object and is safe to use in React.
  }, [location]);
  // const [ style, setStyle] = useState(
  //   navigate = ("/") : style={{ textDecorationLine: "underline", textDecorationStyle: "wavy", textDecorationColor: "#41630F"}}
  // );

    const auth = useSelector((state) => state.auth.accessToken);

  const [show, setShow] = useState(false);
  let navigate = useNavigate();
    const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const home = () => {
    navigate("/");
    setShow(false);
  }
  const products = () => {
    navigate("/products");
    setShow(false);
  }
  const register = () => {
    navigate("/register");
    setShow(false);
  };
  const Feedback = () => {
    navigate("/Feedback");
    setShow(false);
  }
  const Login = () => {
    navigate("/Login");
    setShow(false);
  }
  const ShoppingCart = () => {
    navigate("/ShoppingCart");
    setShow(false);
  }

  const ViewStore = () => {
    navigate("/viewStore");
    setShow(false);

  }
  const HOME = () => {
    navigate("/");
    setShow(false);
  }

  const handleDashboard = () => {
    navigate("/dashboard");
    setShow(false);
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <nav>
      <div className="top">
        <div className="mail nonem">
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=shop.eka.me@gmail.com&cc=shop.eka.me@gmail.com&bcc=shop.eka.me@gmail.com&su=SUBJECT&body=BODY"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#41630F",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MailOutlineIcon className="mailicon" />
            <p className="mailtext">shop.eka.me@gmail.com</p>
          </a>
        </div>
        <div className="call">
          {/* <a
            href="whatsapp://"
            class="btn whatsapp-btn btn-outline whatsapp-outline btn-block"
            title="call on whatsapp"
            style={{
              textDecoration: "none",
              color: "#41630F",
              display: "flex",
              alignItems: "center",
            }}
          > */}
          <a
            href="whatsapp://send?abid=0775946025&text=Hello%2C%20World!"
            class="btn whatsapp-btn btn-outline whatsapp-outline btn-block"
            title="call on whatsapp"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#41630F",
              display: "flex",
              alignItems: "center",
            }}
          >
            <PhoneIphoneOutlinedIcon className="mailicon" />
            <p className="mailtext">0777631108</p>
          </a>
        </div>
        <div className="mail">
          <a
            href="https://maps.google.com/?q=7.106855,79.883751"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#41630F",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LocationOnOutlinedIcon className="location" />
            <p className="mailtext">Ja-Ela, Colombo</p>
          </a>
        </div>
      </div>
      <hr />

      <div className="nav">
        <div className="sameS">
          <div className="logo" onClick={HOME}>
            <img src={logo} alt="shopeka" />
          </div>
        </div>
        <div className="items1">
          <input type="checkbox" id="check" />
          <label
            onClick={() => setShow(!show)}
            htmlFor="check"
            className="checkbtn"
          >
            <MenuIcon />
            <p>MENU</p>
          </label>
          <div className="list">
            <ul>
              <li className={path === "/" && "home"} onClick={home}>
                {" "}
                HOME{" "}
              </li>
              <li className={path === "/products" && "home"} onClick={products}>
                {" "}
                PRODUCTS{" "}
              </li>
              <li
                className={path === "/viewStore" && "home"}
                onClick={ViewStore}
                pathstep={"/viewStore"}
              >
                {" "}
                VIEW STORE{" "}
              </li>
              {path === "/register" ? (
                <li className={path === "/login" && "home"} onClick={Login}>
                  {" "}
                  LOGIN
                </li>
              ) : (
                <li
                  className={path === "/register" && "home"}
                  onClick={register}
                >
                  {" "}
                  REGISTER
                </li>
              )}
              {/* <li className={path === "/requestQuote" && "home"}>
                <RequestQuote />
              </li> */}
            </ul>
          </div>
        </div>
        <div className="navIcon">
          <div className="Icon">
            <Tooltip title="coming soon" placement="top">
              <IconButton>
                <SearchIcon />{" "}
              </IconButton>
            </Tooltip>{" "}
          </div>
          <div className="Icon">
            {" "}
            <IconButton>
              <AddCommentIcon onClick={Feedback} />
            </IconButton>{" "}
          </div>
          <div className="Icon">
            {" "}
            <IconButton>
              {auth ? <ProfilePopup /> : <PermIdentityIcon onClick={Login} />}
            </IconButton>{" "}
          </div>
          <div className="Icon">
            <IconButton
              aria-label="show 4 new mails"
              color="inherit"
              onClick={ShoppingCart}
            >
              <Badge badgeContent={cart.length} color="success">
                <ShoppingBasketIcon />
              </Badge>
            </IconButton>
          </div>
        </div>
      </div>

      <hr className="hrimg" />
      {show && (
        <div
          className="dropdownlist"
        >
          <div className="liItems">
            <ul className="dropdown">
              <li className="home2" onClick={home}>
                {" "}
                HOME <hr />
              </li>
              <li onClick={products}>
                {" "}
                PRODUCTS <hr />
              </li>
              <li onClick={ViewStore}>
                {" "}
                VIEW STORE <hr />
              </li>
              <li onClick={RequestQuote} style={{ display: "none" }}>
                {" "}
                REQUEST A QUOTE <hr />
              </li>
              <li className="IconList" style={{ display: "none" }}>
                {" "}
                SEARCH <hr />
              </li>
              <li className="IconList" onClick={ShoppingCart}>
                {" "}
                CART <hr />
              </li>
              {!auth ? (
                <li
                  className={path === "/register" && "home"}
                  onClick={register}
                >
                  {" "}
                  REGISTER
                  <hr />
                </li>
              ) : (
                <li
                  className={path === "/login" && "home"}
                  onClick={handleLogout}
                >
                  {" "}
                  LOGOUT
                  <hr />
                </li>
              )}
              {auth ? (
                <li
                  className={path === "/dashboard" && "home"}
                  onClick={handleDashboard}
                >
                  {" "}
                  DASHBOARD
                  <hr />
                </li>
              ) : (
                <li className={path === "/" && "home"} onClick={Login}>
                  {" "}
                  LOGIN
                  <hr />
                </li>
              )}
              {/* {path === "/register" ? (
                <li className={path === "/login" && "home"}>
                  {" "}
                  LOGIN
                  {auth ? (
                    <ProfileLOgout />
                  ) : (
                    <PermIdentityIcon onClick={Login} />
                  )}
                  <hr />
                </li>
              ) : (
                <li
                  className={path === "/register" && "home"}
                  onClick={register}
                >
                  {" "}
                  REGISTER
                  <hr />
                </li>
              )} */}
              {/* <li className="IconList" onClick={Login}>
                {" "}
                LOGIN <hr />
              </li> */}
              <li className="IconList" onClick={Feedback}>
                {" "}
                FEEDBACK <hr />
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavBar
