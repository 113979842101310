import React, { useEffect, useState } from "react";
import { CardContent } from "@material-ui/core";
import "./checkout.css";
import TextField from "@mui/material/TextField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputFeild from "../../components/common/TextFeilds";
// import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
// import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { resetItems } from "../../store/actions/cartActions";
import FieldButtons from "../../components/common/FieldButtons";
import axios from "../../utils/lib/axios";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import { login } from "../../store/actions/authActions";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    // height: "100%",
  },
  text: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    border: "none",
    color: "#41630F",
    "&:onclick": {
      border: "1px solid #41630F",
    },
  },
  center: {
    textAlign: "center",
    color: "#393E40",
    fontSize: "1.56rem",
    paddingBottom: "1.2rem",
    fontWeight: "bold",
    marginTop: 0,
    fontFamily: '"Lato",sans-serif',
    margin: "0",
  },
  padding: {
    padding: theme.spacing(3),
    backgroundColor: "#F5F5F5",
    border: "none",
    width: "45vw",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
    },
  },

  button: {
    backgroundColor: "#2cca5c",
    color: "white",

    "&:hover": {
      backgroundColor: "#2cca5c",
      color: "white",
    },
  },

  button2: {
    marginTop: "1rem",
    color: "#2cca5c",
  },
  lostpassword: {
    color: "#393E40",
    textTransform: "none",
    display: "flex",
    alignItems: "flex-start",

    "&:hover": {
      color: "#41630F",
      background: "none",
    },
  },
  accountbtn: {
    color: "#393E40",
    textTransform: "none",
    "&:hover": {
      background: "none",
    },
  },
  newaccount: {
    color: "#41630F",
  },

  register: {
    fontSize: "0.85rem",
  },
  input: {
    fontSize: "0.85rem",
    fontWeight: "500",
  },
  inputs: {
    padding: "0.5rem",
  },
  starRegister: {
    color: "red",
  },
  hvrSpan: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  paddingBg: {
    padding: "3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 0 1.5rem",
    },
  },
}));
function Checkout() {

  const cart = useSelector((state) => state.cart.cart);
  const auth = useSelector((state) => state.auth.accessToken);

  const getTotal = () => {
    let total = 0;
    cart.map((item) => {
      total = total + item.price * item.fQuantity;
      return total;
    });
    return total;
  };

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [alerttwo, setAlerttwo] = useState({
    showAlerttwo: false,
    severity: "success",
    message: "",
  });
  const [alertthree, setAlertthree] = useState({
    showAlertthree: false,
    severity: "success",
    message: "",
  });

  const placeOrder = async () => {
    try {
      await axios.post("/order/", { itemList: cart });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Order added successfully!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Order added successfully!",
        });
        navigate("/thankyou", { state: cart });
        dispatch(resetItems());
      }, 3000);
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
      } else if (error.response.status === 501) {
        setAlert({
          showAlert: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
      }
    }
  };

  const [confirm, setConfirm] = useState(true);
  const [initialValues, setInitialValues] = useState({
    email: "",
    confirmEmail: "",
    userName: "",
    country: "",
    address: "",
    city: "",
    postalCode: "",
    phoneNumber: "",
  });

  const phoneRegExp = /^(?:7|0|(?:\+94))[0-9]{9,11}$/;

  let checkoutSchema = Yup.object().shape({
    email: Yup.string().email().lowercase().required("Email is required!"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Confirm Email must match")
      .required("Confirm Email is required!"),
    userName: Yup.string().min(3).max(150).required("User Name is required!"),
    country: Yup.string().min(3).max(150),
    address: Yup.string().min(3).max(150).required("Address is required!"),
    city: Yup.string().required("City is required!").min(3).max(150),
    postalCode: Yup.string().min(1).max(100000),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required!"),
  });

  // const CssRadio = withStyles({
  //   colorSecondary: {
  //       color: '#41630f9c',
  //       '&$checked': {
  //           color: '#41630F',
  //         },
  //   },
  //   checked: {}
  // })(Radio)

  const submit = async (e) => {
    try {
      await axios.post("/delivery/", e);
      loadData();
      setAlertthree({
        showAlertthree: true,
        severity: "success",
        message: "Delivery details added successfully!",
      });
      setTimeout(() => {
        setAlertthree({
          showAlertthree: false,
          severity: "success",
          message: "Delivery details added successfully!",
        });
      }, 3000);
    } catch (error) {
      if (error.response.status === 401) {
        setAlertthree({
          showAlertthree: true,
          severity: "error",
          message: "Unauthorized!",
        });
        setTimeout(() => {
          setAlertthree({
            showAlertthree: false,
            severity: "error",
            message: "Unauthorized!",
          });
        }, 3000);
      } else if (error.response.status === 501) {
        setAlertthree({
          showAlertthree: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
        setTimeout(() => {
          setAlertthree({
            showAlertthree: false,
            severity: "error",
            message:
              "You are temporary block. Please contact your administrator!",
          });
        }, 3000);
      } else {
        setAlertthree({
          showAlertthree: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(() => {
          setAlertthree({
            showAlertthree: false,
            severity: "error",
            message: "Server error!",
          });
        }, 3000);
      }
    }
  };
  const loadData = async () => {
    const { data } = await axios.get("/delivery/");
    if (data) {
      setConfirm(false);
      setInitialValues({
        email: data.email,
        confirmEmail: data.email,
        userName: data.userName,
        country: data.country,
        address: data.address,
        city: data.city,
        postalCode: data.postalCode,
        phoneNumber: data.phoneNumber,
      });
    }
    else {
      const { data } = await axios.get("/user/me");
      setInitialValues({
        email: data.email,
        confirmEmail: data.email,
        userName: data.userName,
        country: data.country,
        address: data.address,
        city: data.city,
        postalCode: data.postalCode,
        phoneNumber: data.phoneNumber,
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  let loginValues = {
    email: "",
    password: "",
  };

  let LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
    password: Yup.string()
      .matches(new RegExp(/^.{8,}$/), "At least 8 characters required!")
      .required("Password is required!"),
  });

  const submit2 = async (e) => {
    try {
      const data = await axios.post("/user/login", {
        email: e.email,
        password: e.password,
      });
      const { accessToken, message } = data.data;
      dispatch(login(accessToken, message));
      loadData();
      setAlerttwo({
        showAlerttwo: true,
        severity: "success",
        message: "LoggedIn successfully!",
      });
      setTimeout(() => {
        setAlerttwo({
          showAlerttwo: false,
          severity: "success",
          message: "LoggedIn successfully!",
        });
      }, 3000);
    } catch (error) {
      if (error.response.status === 401) {
        setAlerttwo({
          showAlerttwo: true,
          severity: "error",
          message: "Unauthorized!",
        });
        setTimeout(() => {
          setAlerttwo({
            showAlerttwo: false,
            severity: "error",
            message: "Unauthorized!",
          });
        }, 3000);
      } else if (error.response.status === 501) {
        setAlerttwo({
          showAlerttwo: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
        setTimeout(() => {
          setAlerttwo({
            showAlerttwo: false,
            severity: "error",
            message:
              "You are temporary block. Please contact your administrator!",
          });
        }, 3000);
      } else {
        setAlerttwo({
          showAlerttwo: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(() => {
          setAlerttwo({
            showAlerttwo: false,
            severity: "error",
            message: "Server error!",
          });
        }, 3000);
      }
    }
  };

  return (
    <div className="Checkout">
      <Helmet>
        <title>Seafood Shop Eka - Checkout Page</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      <div className="form-Itemdiv">
        <div className="formTitle">
          <h3 className="billingInfoH3">Delivery Information</h3>
          {!auth && <p className="billingInfoP">Please Login Here! </p>}
        </div>
        {!auth && (
          <div style={{ paddingBottom: "2rem" }}>
            <Formik
              initialValues={loginValues}
              onSubmit={submit2}
              validationSchema={LoginSchema}
            >
              {({ dirty, isValid }) => {
                return (
                  <Form style={{ backgroundColor: "#F5F5F5" }}>
                    <CardContent>
                      <p className="checkLoginText">
                        Email <span className="loginStart">*</span>
                      </p>
                      <InputFeild
                        name="email"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        Placeholder="Email"
                        margin="dense"
                      />
                      <p className="checkLoginText">
                        Password <span className="loginStart">*</span>
                      </p>
                      <Field
                        fullWidth
                        name="password"
                        placeholder="Password"
                        required
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        as={TextField}
                        margin="dense"
                        helperText={
                          <ErrorMessage
                            className={classes.errormg}
                            name="password"
                          ></ErrorMessage>
                        }
                        className={classes.text}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "#79747E" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="login_Row">
                        <FieldButtons
                          label="Login"
                          fontcolor="#FFFFFF"
                          buttoncolor="#41630F"
                          buttonborder="none"
                          hoverbgcolor="#41630F"
                          disabled={!dirty || !isValid}
                        />
                      </div>
                      <p
                        className="stylelog"
                        onClick={() => navigate("/LostPassword")}
                      >
                        Lost your password?
                      </p>
                    </CardContent>
                  </Form>
                );
              }}
            </Formik>
            <p
              className="stylelog"
              style={{ paddingLeft: "1rem" }}
              onClick={() => navigate("/register")}
            >
              Don't you have an account? <span>Create your account</span>
            </p>
            {alerttwo.showAlerttwo && (
              <div item md={12} style={{ marginTop: "0.7rem" }}>
                <Alert
                  severity={alerttwo.severity}
                  onClose={() =>
                    setAlerttwo({
                      ...alerttwo,
                      showAlerttwo: false,
                    })
                  }
                >
                  {alerttwo.message}
                </Alert>
              </div>
            )}
          </div>
        )}
        <div className="formBackground">
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={checkoutSchema}
            enableReinitialize
          >
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <CardContent>
                    <p className="inputTextP">
                      Email <span>*</span>
                    </p>
                    <InputFeild
                      name="email"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      disabled={!auth}
                      margin="dense"
                    />
                    <p className="inputTextP">
                      Confirm email address <span>*</span>
                    </p>
                    <InputFeild
                      name="confirmEmail"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                      disabled={!auth}
                    />
                    <div className="checkboxMailing">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked color="success" />}
                          label={
                            <span
                              style={{ fontSize: ".78rem", fontWeight: "bold" }}
                            >
                              {
                                "Join our mailing list, and stay up to date with the special offers from Seafood."
                              }
                            </span>
                          }
                        />
                      </FormGroup>
                    </div>
                    <div container spacing={1}>
                      <div item xs={6}>
                        <p className="inputTextP">
                          User Name/ Company Name <span>*</span>
                        </p>
                        <InputFeild
                          name="userName"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                          disabled={!auth}
                        />
                      </div>
                    </div>
                    <p className="inputTextP">Country (optional)</p>
                    <InputFeild
                      name="country"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      margin="dense"
                      disabled={!auth}
                    />

                    <p className="inputTextP">
                      Address <span>*</span>
                    </p>
                    <InputFeild
                      name="address"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      margin="dense"
                      disabled={!auth}
                    />

                    <div container spacing={1}>
                      <div item xs={6}>
                        <p className="inputTextP">
                          City <span>*</span>
                        </p>
                        <InputFeild
                          name="city"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                          disabled={!auth}
                        />
                      </div>
                      <div item xs={6}>
                        <p className="inputTextP">Postal Code (optional)</p>
                        <InputFeild
                          name="postalCode"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                          disabled={!auth}
                        />
                      </div>
                    </div>
                    <p className="inputTextP">
                      Phone Number <span>*</span>
                    </p>
                    <InputFeild
                      name="phoneNumber"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      margin="dense"
                      disabled={!auth}
                    />
                    {/* <div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox color="success" />}
                          label={
                            <span
                              style={{ fontSize: ".78rem", fontWeight: "bold" }}
                            >
                              {"Save this information for next time"}
                            </span>
                          }
                        />
                      </FormGroup>
                    </div> */}
                    {alertthree.showAlertthree && (
                      <div item md={12} style={{ marginTop: "0.7rem" }}>
                        <Alert
                          severity={alertthree.severity}
                          onClose={() =>
                            setAlertthree({
                              ...alertthree,
                              showAlertthree: false,
                            })
                          }
                        >
                          {alertthree.message}
                        </Alert>
                      </div>
                    )}
                    <FieldButtons
                      label="Save"
                      fontcolor="#FFFFFF"
                      buttoncolor="#41630F"
                      buttonborder="none"
                      hoverbgcolor="#41630F"
                      disabled={!dirty || !isValid}
                    />
                  </CardContent>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <div className="summary-Itemdiv">
        <h3 className="OdrSummaryH3">Order Summary</h3>
        <div className="OrderSummarybg">
          {cart.map((item) => (
            <div className="CategorySummaryCheck">
              <div className="summaryRow">
                <div className="summaryRowPic">
                  <img src={item.image1} alt="pic" />
                </div>
                <div className="checkitemdes">
                  <p className="OStextH4">
                    {item.subName} <br />({item.weight}g)portions
                  </p>
                  <p className="OStextP">Quantity: {item.fQuantity}</p>
                  <p className="OStextP">
                    Category:{" "}
                    <span className="crabs">{item.category[0].name}</span>{" "}
                  </p>
                  <p className="OStextP OStextPmar">Store: Seafood Shop Eka</p>
                </div>
              </div>
              <h4 className="greenText">LKR {item.price}.00</h4>
            </div>
          ))}
          <hr style={{ margin: "1rem 0" }} />

          <div className="subTotalcls">
            <p className="totalShippingT">Subtotal</p>
            <p className="totalShippingPrice">LKR {getTotal()}.00</p>
          </div>
          <div className="shippingCls">
            <p className="totalShippingT">Shipping</p>
            {getTotal() < 50000 ? (
              <p className="totalShippingPrice">+ Delivery Charge</p>
            ) : (
              <p className="totalShippingPrice">Free</p>
            )}
          </div>
          <hr />
          <div className="TotalCls">
            <h3>Total</h3>
            <h3>LKR {getTotal()}.00</h3>
          </div>
          <div style={{ textAlign: "end" }}>
            {getTotal() < 50000 ? (
              <p className="subdetails">+ Delivery Charge</p>
            ) : (
              <p className="subdetails">Free</p>
            )}
          </div>
        </div>
        <div style={{ padding: "2rem 0" }}>
          {alert.showAlert && (
            <div item md={12} style={{ marginBottom: "1rem", marginTop:"0.7rem"}}>
              <Alert
                severity={alert.severity}
                onClose={() =>
                  setAlert({
                    ...alert,
                    showAlert: false,
                  })
                }
              >
                {alert.message}
              </Alert>
            </div>
          )}
          <FieldButtons
            label="Confirm & Place Order"
            fontcolor="#FFFFFF"
            buttoncolor="#41630F"
            buttonborder="none"
            hoverbgcolor="#41630F"
            disabled={confirm || !auth || cart.length === 0}
            onClick={placeOrder}
          />
          <div style={{ fontWeight: "bold" }}>
            {(confirm || !auth || cart.length === 0) && (
              <p>please add delivery details to enable the confirmed order!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
