import React from "react";
import "./404page.css";
import error404 from "./error404.png";
import FieldButtons from "../common/FieldButtons";
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
  let navigate = useNavigate();

  return (
    <div className="error">
      <div className="errorAlign">
        <img src={error404} alt="errorImg" />
        <h3>Sorry,but we did not find </h3>
        <h3>the page you were looking for</h3>
        <div className="backtoButton"  onClick={() => navigate('/')} >
          <FieldButtons
            label="Back to Homepage"
            fontcolor="#FFFFFF"
            buttoncolor="#41630F"
            buttonborder="none"
            hoverbgcolor="#41630F"
            onClick={()=>{
              throw Error('production sentry checking');
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
