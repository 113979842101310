import React, { useState } from "react";
import { Grid, Card, CardContent, CardActions, Button} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import axios from "../../../utils/lib/axios";
import { useNavigate } from "react-router-dom";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";


// validation
import { Formik, Form, Field, ErrorMessage  } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import FieldButtons from "components/common/FieldButtons";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
    color: "#393e40e0",
    fontSize: "1.15rem",
    fontWeight: "bold",
    marginTop: 0,
    fontFamily: '"Lato",sans-serif',
    margin: "0",
  },
  padding: {
    padding: theme.spacing(3),
    backgroundColor: "#F5F5F5",
    border: "none",
    width: "62vw",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      padding:0,
    },
  },
  Wrapper: {
    backgroundColor: "#FFFFFF",
    margin: "0",
    padding: "0",
  },

  button: {
    backgroundColor: "#2cca5c",
    color: "white",

    "&:hover": {
      backgroundColor: "#2cca5c",
      color: "white",
    },
  },
  button2: {
    marginTop: "1rem",
    color: "#2cca5c",
  },
  lostpassword: {
    color: "#393E40",
    textTransform: "none",
    display: "flex",
    alignItems: "flex-start",

    "&:hover": {
      color: "#41630F",
      background: "none",
    },
  },
  accountbtn: {
    color: "#393E40",
    textTransform: "none",
    "&:hover": {
      background: "none",
    },
  },
  newaccount: {
    color: "#41630F",
  },
  fieldStar: {
    color: "red",
  },
  input: {
    fontSize: ".95rem",
  },
  mainDiv: {
    alignContent: "center",
  }

}));

function Login(props) {
  let initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  let SignUpSchema = Yup.object().shape({
    newPassword: Yup.string()
      .matches(new RegExp(/^.{8,}$/), "At least 8 characters required!")
      .required("Password is required!"),
    confirmPassword: Yup.string()
      .required("Please confirm your password!")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Confirm password doesn't match with the new password!"
      ),
  });

    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleMouseNewPassword = () =>
      setShowNewPassword(!showNewPassword);


  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);


  const classes = useStyles();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  let navigate = useNavigate();

  const submit = async (e) => {
    try {
    await axios.post("/user/changePassword", {
        password: e.newPassword,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Password changed successfully",
      });
      setTimeout(() => setAlert({
        showAlert: false,
        severity: 'success',
        message: 'Password changed successfully',
      }), 5000);
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
      } else if (error.response.status === 501) {
        setAlert({
          showAlert: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
      }
    }
  };

  return (
    <div className={classes.mainDiv}>
      <Grid
        justifyContent="center"
        alignItems="center"
        spacing={1}
        p={1}
        lg={12}
        md={12}
        style={{ paddingTop: "0" }}
      >
        <Card className={classes.padding} variant="outlined">
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={SignUpSchema}
          >
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <CardContent>
                    <p className={classes.input}>
                      New Password <span className={classes.fieldStar}>*</span>
                    </p>
                    <Field
                      fullWidth
                      name="newPassword"
                      placeholder="New Password"
                      required
                      type={showNewPassword ? "text" : "password"}
                      variant="outlined"
                      as={TextField}
                      margin="dense"
                      helperText={
                        <ErrorMessage
                          className={classes.errormg}
                          name="newPassword"
                        ></ErrorMessage>
                      }
                      className={classes.text}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickNewPassword}
                              onMouseDown={handleMouseNewPassword}
                            >
                              {showNewPassword ? (
                                <VisibilityOutlinedIcon
                                  sx={{ color: "#79747E" }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  sx={{ color: "#79747E" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <p className={classes.input}>
                      Confirm Password{" "}
                      <span className={classes.fieldStar}>*</span>
                    </p>
                    <Field
                      fullWidth
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      required
                      type={showConfirmPassword ? "text" : "password"}
                      variant="outlined"
                      as={TextField}
                      margin="dense"
                      helperText={
                        <ErrorMessage
                          className={classes.errormg}
                          name="confirmPassword"
                        ></ErrorMessage>
                      }
                      className={classes.text}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickConfirmPassword}
                              onMouseDown={handleMouseConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOutlinedIcon
                                  sx={{ color: "#79747E" }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  sx={{ color: "#79747E" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardContent>
                  <CardActions>
                    <Grid container justifyContent="flex-start">
                      <Grid item>
                        <FieldButtons
                          label="Change Password"
                          fontcolor="#FFFFFF"
                          buttoncolor="#41630F"
                          buttonborder="none"
                          hoverbgcolor="#41630F"
                          disabled={!dirty || !isValid}
                          type="password"
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                  <Grid container>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      className={classes.button2}
                      justifyContent="flex-start"
                    >
                      <Button
                        className={classes.lostpassword}
                        onClick={() => navigate("/LostPassword")}
                      >
                        Lost your password?
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Card>
        {alert.showAlert && (
          <Grid item md={12} style={{ marginTop: "0.7rem" }}>
            <Alert
              severity={alert.severity}
              onClose={() =>
                setAlert({
                  ...alert,
                  showAlert: false,
                })
              }
            >
              {alert.message}
            </Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default connect(null, { login })(Login);
