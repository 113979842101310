import React from 'react';
import '../Description/description.css';
import fishP1 from '../images/fishP1.webp';
import fishP2 from '../images/fishP2.webp';
import fishP3 from '../images/fishP3.webp';
import fishP4 from '../images/fishP4.webp';
import OutlinedButtons from '../../common/OutlinedButtons';
import { useNavigate } from 'react-router-dom';



export default function Description() {
  let navigate = useNavigate();
  return (
    <div className="background">
      <div className="greenScreen">
        <div className="text">
          <h2>SEAFOOD</h2>
        </div>
        <div className="content">
          <div className="text2">
            <h2>LOCAL & EXPORT MARKET</h2>
          </div>
          <p>
            Shop Eka (PVT) Ltd is a leading seafood market place in Sri Lanka
            with fresh seafood delivery!.
          </p>
          <OutlinedButtons
            onClick={() => navigate("/products")}
            label="Shop Now"
            fontcolor="#FFFFFF"
            buttonborder="solid 1px"
            hoverbgcolor="#FFFFFF"
            hoverfontcolor="#41630F"
            buttonpadding="0.4rem 2.5rem"
          />
        </div>
      </div>
      <div className="backImg">
        <div className="productPic">
          <img src={fishP1} alt="productPic" width="23%" height="20%" />
          <img src={fishP2} alt="productPic" width="23%" height="20%" />
          <img
            src={fishP3}
            alt="productPic"
            width="23%"
            height="20%"
            className="imgNone"
          />
          <img
            src={fishP4}
            alt="productPic"
            width="23%"
            height="20%"
            className="imgNone"
          />
        </div>
      </div>
    </div>
  );
}
