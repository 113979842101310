import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import style from './openOrderTable.module.css';
import FieldButtons from 'components/common/FieldButtons';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function CustomizedDialogs({ itemList }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getTotal = () => {
    let total = 0;
    itemList.map((item) => {
      total = total + item.price * item.fQuantity;
      return total;
    });

    return total;
  };

  return (
    <div>
      <FieldButtons
        label='View'
        fontcolor='#FFFFFF'
        buttoncolor='#41630F'
        buttonborder='none'
        hoverbgcolor='#41630F'
        onClick={handleClickOpen}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth={'lg'}
        maxWidth={'lg'}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Order List
        </DialogTitle>
        <table>
          <tr>
            <th className={style.thead}>PRODUCTS</th>
            <th className={style.thead}>PRICE</th>
            <th className={style.thead}>QUANTITY</th>
            <th className={style.thead}>TOTAL</th>
          </tr>

          {itemList?.map((item) => (
            <tr key={item._id}>
              <td className={style.tname}>
                <div className={style.tableProduct}>
                  <img
                    src={item.image1}
                    alt='prod1'
                    className={style.tprodut1}
                  />
                  <p style={{ paddingLeft: '1rem' }}>{item.subName}</p>
                </div>
              </td>
              <td className={style.tname}>RS. {item.price}.00</td>
              <td className={style.tname}>{item.fQuantity}</td>
              <td className={style.tname}>
                RS. {Number(item.price) * Number(item.fQuantity)}.00
              </td>
            </tr>
          ))}
          <div className={style.cartTotal}>
            <p className={style.cartp2}>
              Total: <b>RS. {getTotal()} . 00</b>
            </p>
          </div>
        </table>
      </Dialog>
    </div>
  );
}
