import React from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./viewStore.css";
// import FieldButtons from "../../components/common/FieldButtons";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Companybg from "../../components/Home/images/Companybg.png";
import "./about.css";
import { Grid, CardContent } from "@material-ui/core";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import InfoIcon from "@mui/icons-material/Info";
import Products from "../products/Products"
import {Helmet} from "react-helmet";

function ViewStore() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const time = new Date().getHours();

  return (
    <div className="companyMain">
      <Helmet>
        <title>Seafood Shop Eka - View Store</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      <div className="greenField">
        <p>
          Free shipping for orders{" "}
          <span className="whitePrice">Rs.50000.00</span> and above orders or
          Less distance (2km)
        </p>
        {/* <div className='learnmorebtn'>
        <FieldButtons
          label="Learn more"
          fontcolor="#41630F"
          buttoncolor="#FFFFFF"
          buttonborder="none"
          hoverbgcolor="#FFFFFF"
          buttonpadding=".3rem"
          fontSize=".1rem"
        />
        </div> */}
      </div>
      <div className="relativestore">
        <img src={Companybg} className="companyPic" alt="companyPic" />
        <div className="companyText">
          <h1 className="h1Text">Shop Eka (PVT) Ltd</h1>
          <p className="addressP">467/C, Dadugama, Ja-Ela</p>
        </div>
      </div>

      <TabContext value={value}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            value={value}
            TabIndicatorProps={{ style: { backgroundColor: "#41630F" } }}
          >
            <Tab
              label={<span style={{ color: "#41630F" }}>ABOUT</span>}
              value="1"
            />
            <Tab
              label={<span style={{ color: "#41630F" }}>PRICING</span>}
              value="2"
            />
            <form className="form-searchbar">
              <TextField
                id="search-bar"
                className="text"
                variant="outlined"
                placeholder="Search in store"
                size="small"
              />
              <div className="IconBtn-search">
                <IconButton
                  type="submit"
                  aria-label="search"
                  style={{ padding: "0", margin: ".3rem .3rem 0rem" }}
                >
                  <SearchIcon style={{ fill: "#ffffff" }} />
                </IconButton>
              </div>
            </form>
          </TabList>
        </Box>
        <TabPanel value="2">
          <Products pathstep={"/viewStore"} />
        </TabPanel>
        <TabPanel
          value="1"
          style={{ backgroundColor: "#F7F7F7", padding: "0" }}
        >
          <div className="aboutCompany">
            <div className="companyDetails">
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="about-Container"
              >
                <CardContent className="cardC">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d346.3361581064492!2d79.88357097190217!3d7.1069679408805095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwMDYnMjQuNyJOIDc5wrA1MycwMS41IkU!5e0!3m2!1sen!2slk!4v1690096172555!5m2!1sen!2slk"
                    style={{
                      width: "100%",
                      height: "15rem",
                      loading: "lazy",
                      referrerpolicy: "no-referrer-when-downgrade",
                    }}
                  />
                  <div className="flexdiv">
                    <InfoIcon fontSize="small" className="companyIcons" />
                    <p>
                      Seafood Shop Eka is a leading seafood market place in Sri
                      Lanka with fresh seafood delivery!. Fresh Harvest caters
                      to the local market in Sri Lanka and supplies high quality
                      seafood products to top class hotel chains, leading
                      restaurants and currently in expansion with the local
                      retail market in Sri Lanka.The company also acts as an
                      importer of high quality seafood products which is now
                      becoming a popular demand amongst retail customers in Sri
                      Lanka.
                      <br />
                      <br />
                      The business also imports high-quality seafood items,
                      which are increasingly in demand from Sri Lankan retail
                      customers. In order to provide you with greater
                      convenience and top-notch service, we are currently
                      pleased to introduce our newest online shopping cart. We
                      warmly welcome you and hope you enjoy this platform of
                      bringing high quality, ready to cook, hassle free, door
                      step delivery seafood service to make your life easier!
                    </p>
                  </div>
                  <hr />
                  <div className="flexdiv">
                    <PhoneIcon fontSize="small" className="companyIcons" />
                    <p>0777631108/ 070 339 0432</p>
                  </div>
                  <hr />
                  <div className="flexdiv">
                    <MailOutlineIcon
                      fontSize="small"
                      className="companyIcons"
                    />
                    <p className="mailC">shop.eka.me@gmail.com</p>
                  </div>
                  <hr />
                  <div className="flexdiv">
                    <AccessTimeIcon fontSize="small" className="companyIcons" />
                    {time >= 8 && time <= 21 ? (
                      <p>
                        <span className="open">Open</span> * Closed 9PM Today
                      </p>
                    ) : (
                      <p>
                        <span className="close">Close</span> * Opened 9Am Today
                      </p>
                    )}
                  </div>
                </CardContent>
              </Grid>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}
export default ViewStore;

