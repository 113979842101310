import React, { useState, useEffect, useRef } from "react";
import FieldButtons from "../../components/common/FieldButtons";
import Grid from "@mui/material/Grid";
import ItemCard from "../../components/common/ItemCard/ItemCard";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "../../utils/lib/axios";
import "./product.css";
import PagePath from "../../components/common/pagePath/PagePath";
import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";

// import { emphasize, styled } from '@mui/material/styles';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Chip from '@mui/material/Chip';
// import HomeIcon from '@mui/icons-material/Home';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};
export default function Products({ pathstep }) {
  const [fData, setFData] = useState([]);
  const [category, setCategory] = useState([]);
  const [favorite, setFavorite] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [prettovalue, setPrettovalue] = useState(0);
  const [categoryId, setCategoryId] = useState("");

  const ref = useRef(null);
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const loadData = async (id, filters) => {
    if (id) {
      const { data } = await axios.post("/subCategory/product", {
        id: id,
        number: pageNumber,
      });
      setFData(data.sub);
      setCount(data.count);
    } else {
      const filter = filters ? prettovalue * 57 + 300 : false;
      const { data } = await axios.post("/subCategory/product", {
        number: pageNumber,
        filter,
      });
      setFData(data.sub);
      setCount(data.count);
    }
  };

  const getCategory = async () => {
    const { data } = await axios.get("/category/getCategory");
    setCategory(data);
  };

  const loadData2 = async () => {
    const { data } = await axios.get("/auth/favorite");
    setFavorite(data);
  };

  useEffect(() => {
    loadData2();
    getCategory();
  }, []);

  useEffect(() => {
    loadData(categoryId);
    // eslint-disable-next-line
  }, [pageNumber]);

  const NewPrice = prettovalue * 67 + 300;
  return (
    <div className="productspace">
      <Helmet>
        <title>Seafood Shop Eka - Product Page</title>
        <meta
          name="description"
          content="Seafood Shop Eka Product page is a leading seafood market place in Sri Lanka with fresh seafood delivery!"
        />
      </Helmet>
      {!pathstep && <PagePath setp1={"Product"} setp2={"All Products"} />}
      <Grid
        className="product-grid"
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid container item xs={12} sm={12} md={3} lg={3}>
          <div className="product-div">
            <div className="button-group">
              <p className="product-heading1">PRODUCTS</p>
              <button
                className="product-button"
                onClick={() => {
                  setFData([]);
                  setPageNumber(1);
                  setCategoryId("");
                  loadData();
                  ref.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                <p>All Products</p>
                <i className="fas fa-angle-right picon"></i>
              </button>
              {category.map((item) => (
                <button
                  className="product-button"
                  key={item._id}
                  onClick={() => {
                    setFData([]);
                    setPageNumber(1);
                    setCategoryId(item._id);
                    loadData(item._id);
                    ref.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <p>{item.name}</p>
                  <i className="fas fa-angle-right picon"></i>
                </button>
              ))}
            </div>
            <div className="filter-group">
              <p className="product-heading2">Filter by price</p>
              <Box style={{ width: "100%", padding: "0 0.2rem" }}>
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  onChange={(e) => {
                    setPrettovalue(e.target.value);
                  }}
                  defaultValue={0}
                />
              </Box>
              <p className="product-price">
                Price:{" "}
                <span className="product-price lkr">
                  LKR 300 - LKR {NewPrice}
                </span>
              </p>
              <Tooltip title="coming soon" placement="top">
                <FieldButtons
                  label="Filter"
                  fontcolor="#FFFFFF"
                  buttoncolor="#393E40"
                  buttonborder="none"
                  hoverbgcolor="#393E40"
                  onClick={() => {
                    setFData([]);
                    loadData("", true);
                    ref.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Grid>
        {fData.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              width: "75%",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
            }}
          >
            <CircularProgress color="success" />
          </Box>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            ref={ref}
          >
            {fData.map((item) => (
              <Grid key={item.id} item xs={12} sm={4} md={4} lg={4}>
                <ItemCard
                  item={item}
                  favorite={favorite}
                  loadData2={loadData2}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {fData.length !== 0 && (
          <Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
                padding: "1rem 0",
              }}
            >
              <Stack spacing={1}>
                <Pagination
                  count={count}
                  page={pageNumber}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
    // </div>
  );
}
